import React, { useState, useEffect } from "react";
import plusIcon from "./../../../../images/LeadingIcon.svg";
import "./../../../../../src/styles/common.css"; // Import common styles
import searchIcon from "./../../../../images/search-lg.svg";
import deleteIcon from "./../../../../images/trash-01.svg";
import editIcon from "./../../../../images/edit-01.svg";
import ApiService from "./../../../../Services/ApiService";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TablePagination,
} from "@material-ui/core";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons"; // Import icons from Material-UI
import UserAdd from "./UserAdd";
import UserEdit from "./UserEdit";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { DialogBox } from "../../../Dialog Box";
import { useCallback } from "react"; // Import useCallback
import { Loader } from "../../../Loader";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';

const UserList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [showUserList, setShowUserList] = useState("UserList");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [editData, seteditData] = useState();
  const [deleteData, setDeleteData] = useState();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setdeleteDialogOpen] = useState(false);

  const [dialogData, setDialogData] = useState();
  const [filterData, setFilterData] = useState({
    Index: 0,
    Limit: 15,
    OrderByTable: "",
    OrderByField: "",
    Sort: "",
    Aggregator: "OR",
    Conditions: [
      {
        TableName: "SdMUsers",
        FieldName: "UserName",
        Operator: "like",
        FieldValue: "",
      },
      {
        TableName: "SdMUsers",
        FieldName: "Email",
        Operator: "like",
        FieldValue: "",
      },
      {
        TableName: "SdMUsers",
        FieldName: "FirstName",
        Operator: "like",
        FieldValue: "",
      },
      {
        TableName: "SdMUsers",
        FieldName: "LastName",
        Operator: "like",
        FieldValue: "",
      },
      {
        TableName: "SdMUserrole",
        FieldName: "RoleName",
        Operator: "like",
        FieldValue: "",
      },
    ],
  });
  const [dataSource, setDataSource] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const handleChangePage = (event, newPage) => {
    //   // console.log('sf')
    // const handleCancel = () => {
    //     setShowUserList("UserList");
    //   };
    setPage(newPage);
    // console.log("ddd", event, newPage, page);
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      Index: newPage * rowsPerPage,
    }));
  };
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const storedData = localStorage.getItem("userDetail");

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      setUserData(parsedData);
    }
  }, []);
  useEffect(() => {
    fetchData();
    setDeleteData(false);
  }, [filterData.Index, showUserList, deleteData]);
  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
    // // console.log('ddd',event,page);
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset page to 0 when rows per page changes
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      Index: 0,
      Limit: newRowsPerPage, // Update Limit based on the new rows per page
    }));
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogData(false);

    setDialogOpen(false);
  };
  const DeleteClose = () => {
    setdeleteDialogOpen(false);
  };
  const handleDialogConfirm = () => {
    setDialogData(true);
    updateToggleState();
    handleDialogClose();
  };
  const [userDates, setUserDates] = useState({
    Id: null,
    UserId: null,
    IsActive: false,
  });
  const [deleteId, setdeleteId] = useState();
  const onSlideToggleChange = async (id, event) => {
    const checkeds = event.target.checked;
    try {
      setUserDates({
        ...userDates,
        Id: id,
        UserId: userData.Id,
        IsActive: checkeds,
      });
      handleDialogOpen(userDates);
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const deleteUser = async (id) => {
    try {
      setdeleteDialogOpen(true);
      setdeleteId(id);
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const updateToggleState = async () => {
    try {
      const res = await ApiService.isActiveput("sdmusers/isactive", userDates);
      if (res) {
        const updatedDataSource = dataSource.map((data) => {
          if (data.Id === userDates.Id) {
            return { ...data, IsActive: userDates.IsActive };
          }
          return data;
        });
        setDataSource(updatedDataSource);
    enqueueSnackbar('Status Updated Successfully!',{ variant: 'success', autoHideDuration: 3000 });
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  useEffect(() => {}, []);

  const fetchData = async () => {
    setLoading(true); // Set loading state to true before fetching data
    try {
      const response = await ApiService.post("sdmusers/list", filterData);
      if (response && response.SdMUsers) {
        setDataSource(response.SdMUsers);
        setTotalRecords(response.count);
        setCount(response.count);
      }
      setData(response.data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Set loading state to false after fetching data
    }
  };
  const handleCancel = () => {
    setShowUserList("UserList");
  };
  function debounce(func, delay) {
    let timer;
    return function (...args) {
      const context = this;
      clearTimeout(timer);
      if (delay === 0) {
        func.apply(context, args); // Immediately invoke the function
      } else {
        timer = setTimeout(() => {
          func.apply(context, args);
        }, delay);
      }
    };
  }
  const handleKeyUp = useCallback(
    debounce((event) => {
      const searchQuery = event.target.value.trim();
      setSearch(searchQuery);
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        Conditions: prevFilterData.Conditions.map((condition) => ({
          ...condition,
          FieldValue: searchQuery,
        })),
      }));
    }, 0), // Set delay to 0 for immediate invocation
    []
  );
  useEffect(() => {
    fetchData(); // Call your fetch data function here
  }, [filterData.Conditions, filterData]);
  // useEffect(() => {

  //   fetchData(); // Call fetchData initially, and every time filterData changes

  // }, [filterData.Conditions]);
  const handleSort = (column) => {
    // console.log("cccc", column);
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        OrderByField: column,
        Sort: sortOrder,
        OrderByTable: "SdMUsers",
      }));
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };
  const renderSortIcon = (column) => {
    if (sortBy === column) {
      return sortOrder === "asc" ? <ArrowUpward className='sortIcon'/> : <ArrowDownward className='sortIcon' />;
    }
    return null;
  };
  function handleEditClick(data) {
    setShowUserList("UserEdit");
    seteditData(data);
  }
  const handleDeleteClick = async () => {
    try {
      const res = await ApiService.delete("sdmusers", deleteId);
      if (res) {
        setDeleteData(true);
        setdeleteDialogOpen(false);
    enqueueSnackbar('Deleted Successfully!',{ variant: 'success', autoHideDuration: 3000 });
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  // Snackbar
  return (
    <div>
      <DialogBox
        title={"Confirmation"}
        content={"Are you sure want to active/inactive this list?"}
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
      <DialogBox
        title={"Confirmation"}
        content={"Are you sure want to delete this list?"}
        open={deleteDialogOpen}
        onClose={DeleteClose}
        onConfirm={handleDeleteClick}
      />
      {loading && <Loader />}
      {showUserList === "UserList" ? (
        <div>
          <div className="addUser">
            <p className="indicatorText">User List</p>
            <div className="SearchBtn">
              <img src={searchIcon} alt="" />
              <form>
                <input
                  type="text"
                  placeholder="Search User List"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyUp={handleKeyUp}
                />
              </form>
            </div>
            <Button
              className="addUserBtn"
              onClick={() => setShowUserList("UserAdd")}
            >
              <div className="button-content">
                <img src={plusIcon} alt="" />
                <span>Add User Account</span>
              </div>
            </Button>
          </div>
          <div className="tableContainer">
            {/* <TableCompontent data={userData}/> */}
            <Paper className="wholeTable">
              {/* Use Paper as the container */}
              <Table>
                <TableHead>
                  {/* Table header */}
                  <TableRow className="tr">
                    <TableCell
                      onClick={() => handleSort("UserName")}
                      className="tableHead"
                    >
                      Username
                      {renderSortIcon("UserName")}
                    </TableCell>
                    <TableCell align="left" className="tableHead">
                      Email
                    </TableCell>
                    <TableCell align="left" className="tableHead">
                      Name
                    </TableCell>
                    <TableCell align="left" className="tableHead">
                      Role
                    </TableCell>
                    <TableCell align="left" className="tableHead">
                      Status
                    </TableCell>
                    <TableCell align="left" className="tableHead">
                      Action
                    </TableCell>
                    {/* <TableCell align="left">Role</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Action</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Table body */}
                  {dataSource.length === 0 ? (
    <TableRow>
      <TableCell colSpan={6} align="center" style={{ textAlign: "center", verticalAlign: "middle" }}>
        No data available
      </TableCell>
    </TableRow>
  ) : (
    dataSource.map((dataSource, index) => (
      <TableRow key={index}>
        <TableCell align="left" className="tableBody">
          {dataSource.UserName}
        </TableCell>
        <TableCell align="left" className="tableBody">
          {dataSource.Email}
        </TableCell>
        <TableCell align="left" className="tableBody">
          {dataSource.FirstName} {dataSource.LastName}
        </TableCell>
        <TableCell align="left" className="tableBody">
          {dataSource.SdMUserrole.RoleName}
        </TableCell>

             
        <TableCell align="left">
          <label className={userData.Id === dataSource.Id ? 'switch disableBtn' : 'switch'}>
            <input
              className="switch-input"
              type="checkbox"
              checked={dataSource.IsActive}
              onChange={(event) =>
                onSlideToggleChange(dataSource.Id, event)
              }
              disabled={userData.Id === dataSource.Id ? true : false}
            />
            <span
              className="switch-label"
              data-on="Active"
              data-off="Inactive"
            ></span>
            <span className="switch-handle"></span>
          </label>
        </TableCell>
        <TableCell align="left">
          <img
            src={editIcon}
            alt=""
            className="action cursor"
            onClick={() => handleEditClick(dataSource)}
          />
          <img
            src={deleteIcon}
            alt=""
            className={userData.Id === dataSource.Id ? 'action cursor disableBtn' : 'action cursor'}
            onClick={() => userData.Id === dataSource.Id ? null :deleteUser(dataSource.Id)}
          />
        </TableCell>
      </TableRow>
    ))
  )}
                 
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[15, 25, 50, 75, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                style={{
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "white",
                }}
              />
            </Paper>
          </div>
        </div>
      ) : showUserList === "UserAdd" ? (
        <UserAdd onCancel={handleCancel} />
      ) : (
        <UserEdit onCancel={handleCancel} dataSource={editData} />
      )}{" "}
    </div>
  );
};

export default UserList;
