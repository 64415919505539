import React, { useState,useEffect} from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import ApiService from "../../../Services/ApiService";
import InfoIcon from "@material-ui/icons/Info";
import leftArrow from "./../../../images/left-arrow.svg";
const PackageTypeEdit = ({ onCancel,dataSource }) => {
    // console.log(dataSource)
    const [formData, setFormData] = useState({
      PackageType: dataSource.PackageType,
      });
    
      useEffect(() => {
  
      
      }, []);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
      function getCurrentDateTime() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
        const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');
      
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
      }
      const [userData, setUserData] = useState(null);
      useEffect(() => {
        const storedData = localStorage.getItem('userDetail');
    
        if (storedData) {
          const parsedData = JSON.parse(storedData);
    
          setUserData(parsedData);
        }
      }, []);
      const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log('Form submitted:', formData);
        const updatedFormData = {
            ...formData,
            Id:dataSource.Id,
            CreatedOn: dataSource.CreatedOn,
            CreatedBy: dataSource.CreatedBy,
            ModifiedBy:userData.Id,
            ModifiedOn:getCurrentDateTime(),
          };
          setFormData(updatedFormData);
          try {
            const res = await ApiService.put("sdmpackagetype", updatedFormData);
            if (res) {
                handleCancel()
            }
          } catch (error) {
            console.error('Error updating toggle state:', error);
          }
        // console.log('Form submitted:', updatedFormData);
      };
    
      const handleCancel = () => {
        // Handle cancel action
        onCancel();
      };
      const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" };
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", options);
      };
      const formattedDate = formatDate(dataSource?dataSource.CreatedOn:'');
      const ModifiedformattedDate = formatDate(dataSource?dataSource.ModifiedOn:'-');
  return (
    <div className='mainContainer'>
     <div className="dis-flex headTitle">
       <img src={leftArrow} alt="" onClick={handleCancel} className='cursor'/>
        <p>Edit Package Type</p>
    </div>
    <form onSubmit={handleSubmit} className='m-t-24'>
      <div className="Content">
        <div className="displayFlex">
          <div className="example-container m-b">
            <TextField
              label="Package Type Name"
              name="PackageType"
              value={formData.PackageType}
              onChange={handleChange}
              required
              maxLength={50}
              className='commonInput'
              InputProps={{
                endAdornment: (
                  <InfoIcon className='Icon-style' title="Supports alphabets, numbers and up to 50 characters." />
                ),
              }}
            />
          </div>
        </div>
        <div className="example-container">
              <div className="commonInput m-24">
                <p className="post">Posted on</p>
                <p className="postDetail">
                  {formattedDate ? formattedDate : ''} By {dataSource ? dataSource.CreatedByName : ''}
                </p>
              </div>
            </div>
            <div className="example-container">
              <div className="commonInput m-24">
                <p className="post">Modified on</p>
                <p className="postDetail">
                  {ModifiedformattedDate ? ModifiedformattedDate : ''} By {dataSource ? dataSource.ModifiedByName : '-'}
                </p>
              </div>
            </div>
      </div>
      <div className="submitButton">
        <Button type="submit"  className="btn">Update</Button>
        <Button className="cancelBtn" onClick={handleCancel}>Cancel</Button>
      </div>
    </form>
    </div>
  );
};

export default PackageTypeEdit;
