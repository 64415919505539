const environment = {
    BASE_URL: 'https://sdadminapi.hakunamatatatech.io',
    // CUSTOMER_BASE_URL:'https://sdcustomerapi.hakunamatatatech.io',
    
    // BASE_URL: 'http://selfcarecms.sundirect.in/cmsapi',
    BASE_ROUTE : '/admin',
    API_ENDPOINT: 'api',
    production: false,
  };
  
  export default environment;