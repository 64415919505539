import React, { useState, useEffect, useRef } from "react";
import leftArrow from "./../../../../src/images/left-arrow.svg";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import ApiService from "../../../Services/ApiService";

import Editor from '../../../components/quillEditor/quillEditor'
import { useSnackbar } from 'notistack';

const DynamicPageEdit = ({ onCancel, dataSource }) => {
  const quillRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [imageError, setimageError] = useState(null);
  const [imageFile, setimageFile] = useState(null); 

  const [formData, setFormData] = useState({
    Id: dataSource.Id,
    PageKey: dataSource.PageKey,
    PageTitle: dataSource.PageTitle,
    ContentHtml: dataSource.ContentHtml,
    ReviewStatus: dataSource.ReviewStatus,
    MetaTitle: dataSource.MetaTitle,
    MetaKeyword: dataSource.MetaKeyword,
    MetaDescription: dataSource.MetaDescription,
    IsActive: dataSource.IsActive ? "Active" :"Inactive",
  });
  const [loading, setLoading] = useState(true);
  const [editor, setEditor] = useState(true); // State variable for textarea value
  const onSlideToggleChange = (even)=>{
    setEditor(!editor);
  }

  useEffect(() => {}, []);
  const statusOptions = [
    { value: "Active", viewValue: "Active" },
    { value: "Inactive", viewValue: "Inactive" },
  ];
  const reviewOptions = [
    { value: "pendingReview", viewValue: "Pending Review" },
    { value: "published", viewValue: "Published" },
  ];
  const TypesOptions = [
    { value: "logoGrid", viewValue: "Logo Grid" },
    { value: "slider", viewValue: "Slider" },
    { value: "grid", viewValue: "Grid" },
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  function getCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const storedData = localStorage.getItem("userDetail");

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      setUserData(parsedData);
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Form submitted:", formData);
    if(formData.ContentHtml && String(formData.ContentHtml).trim() != ''){
      const updatedFormData = {
        ...formData,
        IsActive: formData.IsActive == "Active" ? true : false,
      };
      setFormData(updatedFormData);
      try {
        const res = await ApiService.put("sdmdynamicpage", updatedFormData);
        if (res) {
          if(!res.Error){
            enqueueSnackbar('Page Updated Successfully!',{ variant: 'success', autoHideDuration: 3000 });
            onCancel(); //To go back to list page after form submission
          }else{
            const resetFormData = {
              ...formData,
              IsActive: (formData.IsActive === true) ? "Active" : "Inactive",
            };
            setFormData(resetFormData);
            enqueueSnackbar(res.Message, { variant: 'error', autoHideDuration: 3000 });
          }
        }
      } catch (error) {
        console.error("Error updating toggle state:", error);
      }
    }else{
      enqueueSnackbar("Content can't be empty.", { variant: 'error', autoHideDuration: 3000 });
    }
    // // console.log("Form submitted:", updatedFormData);
  };

  const handleCancel = () => {
    // Handle cancel action
    onCancel();
  };
  const handleChange1 = (content) => {
    setFormData((prevData) => ({
      ...prevData,
      ContentHtml: content,
    }));
  };
  return (
    <div className="mainContainer">
      <div className="dis-flex headTitle">
        <img src={leftArrow} alt="" onClick={handleCancel} className="cursor" />
        <p>Edit Dynamic Page</p>
      </div>
      <form onSubmit={handleSubmit} className="m-t-24">
        <div className="Content">
          <div className="displayFlex m-b ml--48">
            <div className="example-container">
            </div>
            <div className="example-container">
              <TextField
                label="Page Key"
                name="PageKey"
                value={formData.PageKey}
                onChange={handleChange}
                required
                maxLength={50}
                className="commonInput pointer-events-none"
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="example-container">
              <TextField
                label="Content Title"
                name="PageTitle"
                value={formData.PageTitle}
                onChange={handleChange}
                required
                maxLength={50}
                className="commonInput"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
          </div>
          <div className="example-container m-b">
            <div className="checkbox-lable m-24">Content</div>
            <label className="switch">
            <input
              className="switch-input"
              type="checkbox"
              checked={editor}
              onChange={(event) => onSlideToggleChange(event)}
            />
            <span className="switch-label" data-on="Editor" data-off="HTML"></span>
            <span className="switch-handle"></span>
            </label>
            {!editor &&  <textarea
                            name="ContentHtml"
                            required
                            className="multiSelectWidth"
                            placeholder="Write something"
                            value={formData.ContentHtml} 
                            style={{ 'minHeight': '263.5px', 'background':'transparent','border': '1px solid #ccc', 'margin-bottom':'-6px' }}
                            onChange={(event)=>handleChange1(event.target.value)}
                        />}
            {editor && <Editor
            ref={quillRef}
            defaultValue={formData.ContentHtml}
            onTextChange={handleChange1}
            placeHolder={"Write something"}
            />}
          </div>
          <div className="example-container m-b">
            <TextField
              label="Meta / PageTitle"
              name="MetaTitle"
              value={formData.MetaTitle}
              onChange={handleChange}
              required
              maxLength={50}
              className="commonInput multiSelectWidth"
              InputProps={{
                endAdornment: (
                  <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className="Icon-style" />
                  </Tooltip>
                ),
              }}
            />
          </div>
          <div className="example-container m-b">
            <TextField
              label="Meta Keywords"
              name="MetaKeyword"
              value={formData.MetaKeyword}
              onChange={handleChange}
              required
              maxLength={50}
              className="commonInput multiSelectWidth"
              InputProps={{
                endAdornment: (
                  <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className="Icon-style" />
                  </Tooltip>
                ),
              }}
            />
          </div>
          <div className="example-container m-b">
            <TextField
              label="Meta Description"
              name="MetaDescription"
              value={formData.MetaDescription}
              onChange={handleChange}
              required
              maxLength={50}
              className="commonInput multiSelectWidth"
              InputProps={{
                endAdornment: (
                  <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className="Icon-style" />
                  </Tooltip>
                ),
              }}
            />
          </div>
          <div className="displayFlex m-b">
            <div className="example-container">
              <FormControl>
                <InputLabel>Review Status</InputLabel>
                <Select
                  name="ReviewStatus"
                  value={formData.ReviewStatus}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {reviewOptions.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.viewValue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="example-container">
              <FormControl>
                <InputLabel>Status</InputLabel>
                <Select
                  name="IsActive"
                  value={formData.IsActive}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {statusOptions.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.viewValue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="submitButton">
          <Button type="submit" className="btn">
            Update
          </Button>
          <Button className="cancelBtn" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};
export default DynamicPageEdit;
