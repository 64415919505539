import React, { useState,useEffect} from 'react';
import leftArrow from './../../../../images/left-arrow.svg';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import ApiService from './../../../../Services/ApiService'
import InfoIcon from '@material-ui/icons/Info'; 
import CryptoJS from 'crypto-js';

const UserEdit = ({ onCancel,dataSource }) => {
    // console.log(dataSource)
    const [formData, setFormData] = useState({
        UserName: dataSource.UserName,
        Password: decryptData(dataSource.Password),
        FirstName: dataSource.FirstName,
        LastName: dataSource.LastName,
        Email: dataSource.Email,
        RoleId: dataSource.RoleId,
        IsActive: dataSource.IsActive == true ? "Active" : "Inactive" ,
      });
  const [roleOptions, setroleOptions] = useState(null);
  const [loading, setLoading] = useState(true);

      const fetchData = async () => {
   const filterData = {
    "Index": 0,
    "Limit": 100000,
    "OrderByTable": "",
    "OrderByField": "",
    "Sort": "",
    "Aggregator": "",
    "Conditions": [
      
    ]
  }
        const response = ApiService.post('sdmuserrole/list', filterData)
        .then(response => {
            setroleOptions(response.SdMUserrole)
            
        })
        .catch(error => {
          console.error('Error:', error);
          setLoading(false);
        });
       
      }

      function decryptData(encryptedText) {
        const secretKey = "SunDirectAdminLoginPSWDSecretKey";
        const salt = "SUNDIRECTLOGIN01";
        // take first 32 bytes as key (like in C# code)
        var key = CryptoJS.enc.Utf8.parse(secretKey);
        // skip first 32 bytes and take next 16 bytes as IV
        var iv = CryptoJS.enc.Utf8.parse(salt);
        // use the same encoding as in C# code, to convert string into bytes
        var data = CryptoJS.enc.Base64.parse(encryptedText);
        var decrypted = CryptoJS.AES.decrypt({ ciphertext: data }, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
        return decryptedText.toString();
      }
      function encryptData(plainText) {
        const secretKey = "SunDirectAdminLoginPSWDSecretKey";
        const salt = "SUNDIRECTLOGIN01";
        // take first 32 bytes as key (like in C# code)
        var key = CryptoJS.enc.Utf8.parse(secretKey);
        // skip first 32 bytes and take next 16 bytes as IV
        var iv = CryptoJS.enc.Utf8.parse(salt);
        // use the same encoding as in C# code, to convert string into bytes
        var data = CryptoJS.enc.Utf8.parse(plainText);
        var encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        // console.log(encrypted.toString());
        return encrypted.toString();
      }
      function getCurrentDateTime() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
        const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');
      
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
      }
      const [userData, setUserData] = useState(null);
      useEffect(() => {
        const storedData = localStorage.getItem('userDetail');
    
        if (storedData) {
          const parsedData = JSON.parse(storedData);
    
          setUserData(parsedData);
        }
      }, []);
      useEffect(() => {
  
        fetchData();
      
      }, []);
      const statusOptions = [
        { value: 'Active', viewValue: 'Active' },
        { value: 'Inactive', viewValue: 'Inactive' },
      ];
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        // console.log(value)
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission
        // console.log('Form submitted:', formData);
        const updatedFormData = {
            ...formData,
            Password: encryptData(formData.Password),
            Id:dataSource.Id,
            CreatedOn: dataSource.CreatedOn,
            CreatedBy: dataSource.CreatedBy,
            ModifiedBy:userData.Id,
            ModifiedOn:getCurrentDateTime(),
            SdMUserrole:userData.SdMUserrole,
            IsActive:formData.IsActive == "Active" ? true : false,
            SdMUserrole:{}
          };
          setFormData(updatedFormData);
          try {
            const res = await ApiService.put("sdmusers", updatedFormData);
            if (res) {
                handleCancel()
            }
          } catch (error) {
            console.error('Error updating toggle state:', error);
          }
        // console.log('Form submitted:', updatedFormData);
      };
    
      const handleCancel = () => {
        // Handle cancel action
        onCancel();
      };
      const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" };
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", options);
      };
      const formattedDate = formatDate(dataSource?dataSource.CreatedOn:'');
      const ModifiedformattedDate = formatDate(dataSource?dataSource.ModifiedOn:'-');
  return (
    <div className='mainContainer'>
     <div className="dis-flex headTitle">
       <img src={leftArrow} alt="" onClick={handleCancel} className='cursor'/>
        <p>Edit User Account</p>
    </div>
    <form onSubmit={handleSubmit} className='m-t-24'>
      <div className="Content">
        <div className="displayFlex m-b">
          <div className="example-container">
            <TextField
              label="Username"
              name="UserName"
              value={formData.UserName}
              onChange={handleChange}
              required
              inputProps={{ maxLength: 50 }}
              className='commonInput'
              InputProps={{
                endAdornment: (
                  <InfoIcon className='Icon-style' title="Supports alphabets, numbers and up to 50 characters." />
                ),
              }}
            />
          </div>
          <div className="example-container">
            <TextField
              type="password"
              label="Password"
              name="Password"
              className='commonInput'
              // value={decryptData(formData.Password)}
              value={formData.Password}
              onChange={handleChange}
              required
            />
          </div>
          <div className="example-container">
            <TextField
              label="Firstname"
              name="FirstName"
              value={formData.FirstName}
              onChange={handleChange}
              required
              className='commonInput'
              inputProps={{ maxLength: 50 }}
              InputProps={{
                endAdornment: (
                  <InfoIcon className='Icon-style' title="Supports alphabets, numbers and up to 50 characters." />
                ),
              }}
            />
          </div>
        </div>
        <div className="displayFlex m-b">
          <div className="example-container">
            <TextField
              label="Lastname"
              name="LastName"
              value={formData.LastName}
              onChange={handleChange}
              required
              className='commonInput'
              inputProps={{ maxLength: 50 }}
              InputProps={{
                endAdornment: (
                  <InfoIcon className='Icon-style' title="Supports alphabets, numbers and up to 50 characters." />
                ),
              }}
            />
          </div>
          <div className="example-container">
            <TextField
              label="Email"
              name="Email"
              value={formData.Email}
              onChange={handleChange}
              required
              className='commonInput'
              error={formData.Email && !/^\S+@\S+\.\S+$/.test(formData.Email)}
              helperText={formData.Email && !/^\S+@\S+\.\S+$/.test(formData.Email) && 'Please enter a valid email address.'}
            />
          </div>
          <div className="example-container">
            <FormControl>
              <InputLabel>Role</InputLabel>
              <Select
                name="RoleId"
                value={formData.RoleId}
                onChange={handleChange}
                required
              className='commonInput'
              >
                {roleOptions !== null ? (
      roleOptions.map((role) => (
        <MenuItem key={role.Id} value={role.Id}>{role.RoleName}</MenuItem>
      ))
    ) : (
      <MenuItem value="">Loading...</MenuItem>
    )}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="example-container m-b">
          <FormControl >
            <InputLabel>Status</InputLabel>
            <Select
              name="IsActive"
              value={formData.IsActive}
              onChange={handleChange}
              required
              className='commonInput'
            >
              {statusOptions.map((status) => (
                <MenuItem key={status.value} value={status.value}>{status.viewValue}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="example-container">
              <div className="commonInput m-24">
                <p className="post">Posted on</p>
                <p className="postDetail">
                  {formattedDate ? formattedDate : ''} By {dataSource ? dataSource.CreatedByName : ''}
                </p>
              </div>
            </div>
            <div className="example-container">
              <div className="commonInput m-24">
                <p className="post">Modified on</p>
                <p className="postDetail">
                  {ModifiedformattedDate ? ModifiedformattedDate : ''} By {dataSource ? dataSource.ModifiedByName : '-'}
                </p>
              </div>
            </div>
      </div>
      <div className="submitButton">
        <Button type="submit"  className="btn">Update</Button>
        <Button className="cancelBtn" onClick={handleCancel}>Cancel</Button>
      </div>
    </form>
    </div>
  );
};

export default UserEdit;