import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import SessionStyles from '../../styles/Session';
import logo from '../../../src/images/sun-direct-vector-logo 2.svg';
import ApiService from '../../Services/ApiService';
import CryptoJS from 'crypto-js';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';

const Signin = (props) => {
  const { classes, history } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  function encryptData(data, secretKey, saltKey) {
    const key = CryptoJS.enc.Utf8.parse(secretKey + saltKey);
    const iv = CryptoJS.lib.WordArray.random(16); // Random IV
    const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv });
    return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
  }
  
  const getCurrentDateTime = () => {
    const currentDate = new Date();
    const year = currentDate.getUTCFullYear();
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
    const day = String(currentDate.getUTCDate()).padStart(2, '0');
    const hours = String(currentDate.getUTCHours()).padStart(2, '0');
    const minutes = String(currentDate.getUTCMinutes()).padStart(2, '0');
    // return `${year}${month}${day}${hours}${minutes}`;
    return `${year}${month}${day}${hours}`+'mm';

  };
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiService.post('sdmusers/login', {
        username: username,
         password: encryptData(password, 'SD2024061810mmAL', 'sundthal')
      });
      // console.log(response.data); // handle successful login response
      if (response.data) {
        localStorage.setItem('token', response.token.AuthToken);
        localStorage.setItem('userDetail', JSON.stringify(response.data));
        history.push("/dashboard/system/configuration"); // Redirect to /system/configuration
        enqueueSnackbar('Login Successfully!',{ variant: 'success', autoHideDuration: 3000 });
      } else {
        enqueueSnackbar(response.response,{ variant: 'error', autoHideDuration: 3000 });

      }
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={classNames(classes.session, classes.background)}>
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <img src={logo} alt="" />
          <Card className='signIncard'>
            <CardContent>
              <form onSubmit={handleLogin}>
                <div className="text-xs-center pb-xs subTitle">
                  <p className='head'>Admin Login</p>
                  <p className='subT'>Enter your credentials to access your account</p>
                </div>
                <TextField
                  id="username"
                  label="Username"
                  className={classes.textField}
                  fullWidth
                  margin="normal"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  id="password"
                  label="Password"
                  className={classes.textField}
                  type={showPassword ? 'text' : 'password'} // Toggle input type based on showPassword state
                  fullWidth
                  required
                  margin="normal"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{ // Add eye icon to toggle password visibility
                    endAdornment: (
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    ),
                  }}
                />
                <Button variant="raised" className='loginbtn' fullWidth type="submit">Login</Button>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

Signin.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(SessionStyles)(withRouter(Signin));