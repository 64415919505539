import axios from 'axios';
import environment from '../Environment/environment';
import {useParams, useHistory, useLocation} from "react-router-dom"
// const history = useHistory()
const BASE_URL = environment.BASE_URL; // Your base API URL
const CUSTOMER_BASE_URL = environment.CUSTOMER_BASE_URL

const ApiService = {
  enqueueSnackbar: null, // Initialize enqueueSnackbar function

  setEnqueueSnackbar(enqueueSnackbarFunc) {
    this.enqueueSnackbar = enqueueSnackbarFunc;
  },
  getToken() {
    return localStorage.getItem('token');
  },

  // Function to fetch all items

  // Function to fetch a single item by ID
  async getOne(resource, id) {
    try {
      const token = this.getToken();
      const response = await axios.get(`${BASE_URL}/${resource}/${id}`,{
        headers: { Authorization: `Bearer ${token}` }});
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // this.enqueueSnackbar('Unauthorized', { variant: 'error', autoHideDuration: 3000 });
        localStorage.removeItem('token');
        window.location.href = '/signin'; // Redirect to signin page
      } else {
        this.enqueueSnackbar('An error occurred. Please try again later.', { variant: 'error', autoHideDuration: 3000 });
      }
      console.error(`Error fetching ${resource} with ID ${id}:`, error);
      throw error;
    }
  },

  // Function to create a new item
  async post(resource, data) {
    try {
      const token = this.getToken();
      const response = await axios.post(`${BASE_URL}/${resource}`, data, {
        headers: { Authorization: `Bearer ${token}` }});
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // this.enqueueSnackbar('Unauthorized', { variant: 'error', autoHideDuration: 3000 });
        localStorage.removeItem('token');
        window.location.href = '/signin'; // Redirect to signin page
        // return Promise.reject(error);
      } else {
        this.enqueueSnackbar('An error occurred. Please try again later.', { variant: 'error', autoHideDuration: 3000 });
      }
      console.error(`Error creating ${resource}:`, error);
      throw error;
    }
  },
  async export(resource) {
    try {
      const token = this.getToken();
      const response = await axios.post(`${BASE_URL}/${resource}`, null, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob' // Set response type to blob
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // this.enqueueSnackbar('Unauthorized', { variant: 'error', autoHideDuration: 3000 });
        localStorage.removeItem('token');
        window.location.href = '/signin'; // Redirect to signin page
      } else {
        this.enqueueSnackbar('An error occurred. Please try again later.', { variant: 'error', autoHideDuration: 3000 });
      }
      console.error(`Error exporting ${resource}:`, error);
      throw error;
    }
  },
  // Function to update an existing item by ID
  async put(resource, data) {
    try {
      const token = this.getToken();
      const response = await axios.put(`${BASE_URL}/${resource}`, data,{
        headers: { Authorization: `Bearer ${token}` }});
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // this.enqueueSnackbar('Unauthorized', { variant: 'error', autoHideDuration: 3000 });
        localStorage.removeItem('token');
        window.location.href = '/signin'; // Redirect to signin page
      } else {
        this.enqueueSnackbar('An error occurred. Please try again later.', { variant: 'error', autoHideDuration: 3000 });
      }
      console.error(`Error updating ${resource} with ID:`, error);
      throw error;
    }
  },
  async isActiveput(resource, data) {
    try {
      const token = this.getToken(); // Use regular function declaration here
      const response = await axios.put(`${BASE_URL}/${resource}`, data,{
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // this.enqueueSnackbar('Unauthorized', { variant: 'error', autoHideDuration: 3000 });
        localStorage.removeItem('token');
        window.location.href = '/signin'; // Redirect to signin page
      } else {
        this.enqueueSnackbar('An error occurred. Please try again later.', { variant: 'error', autoHideDuration: 3000 });
      }
      console.error(`Error updating ${resource} with ID:`, error);
      throw error;
    }
  },
  async getAll(resource,language) {
    try {
      const token = this.getToken(); // Use regular function declaration here
      const response = await axios.get(`${BASE_URL}/${resource}`, {
        headers: { Authorization: `Bearer ${token}` ,
        'Accept-Language': language?language:"",}
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // this.enqueueSnackbar('Unauthorized', { variant: 'error', autoHideDuration: 3000 });
        localStorage.removeItem('token');
        window.location.href = '/signin'; // Redirect to signin page
      } else {
        this.enqueueSnackbar('An error occurred. Please try again later.', { variant: 'error', autoHideDuration: 3000 });
      }
      console.error('Error fetching data:', error);
      throw error;
    }
  },
  async CustomerApigetAll(resource,language) {
    try {
      const token = this.getToken(); // Use regular function declaration here
      const response = await axios.get(`${CUSTOMER_BASE_URL}/${resource}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept-Language': language,
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // this.enqueueSnackbar('Unauthorized', { variant: 'error', autoHideDuration: 3000 });
        localStorage.removeItem('token');
        window.location.href = '/signin'; // Redirect to signin page
      } else {
        this.enqueueSnackbar('An error occurred. Please try again later.', { variant: 'error', autoHideDuration: 3000 });
      }
      console.error('Error fetching data:', error);
      throw error;
    }
  },
  async CustomerApiput(resource, data,language) {
    try {
      const token = this.getToken();
      const response = await axios.put(`${BASE_URL}/${resource}`, data,{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept-Language': language,
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // this.enqueueSnackbar('Unauthorized', { variant: 'error', autoHideDuration: 3000 });
        localStorage.removeItem('token');
        window.location.href = '/signin'; // Redirect to signin page
      } else {
        this.enqueueSnackbar('An error occurred. Please try again later.', { variant: 'error', autoHideDuration: 3000 });
      }
      console.error(`Error updating ${resource} with ID:`, error);
      throw error;
    }
  },

  // Function to delete an item by ID
  async delete(resource, id) {
    const token = this.getToken();
    try {
      const response = await axios.delete(`${BASE_URL}/${resource}/${id}`,{
        headers: { Authorization: `Bearer ${token}` }});
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // this.enqueueSnackbar('Unauthorized', { variant: 'error', autoHideDuration: 3000 });
        localStorage.removeItem('token');
        window.location.href = '/signin'; // Redirect to signin page
      } else {
        this.enqueueSnackbar('An error occurred. Please try again later.', { variant: 'error', autoHideDuration: 3000 });
      }
      console.error(`Error deleting ${resource} with ID ${id}:`, error);
      throw error;
    }
  },
};

export default ApiService;