import React, { useState,useEffect } from 'react';
import leftArrow from './../../../../src/images/left-arrow.svg';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel,IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info'; 
import Tooltip from '@material-ui/core/Tooltip';
import ApiService from '../../../Services/ApiService';

const ErrorSymptomsAdd = ({ onCancel }) => {
  const [errorType, seterrorType] = useState(null);
  const [errorTypeData, seterrorTypeData] = useState()
  const fetchData = async () => {
    const filterData = {
     "Index": 0,
     "Limit": 100000,
     "OrderByTable": "",
     "OrderByField": "",
     "Sort": "",
     "Aggregator": "",
     "Conditions": [
       
     ]
   }
         const response = ApiService.post('sdmerrortype/list', filterData)
         .then(response => {
             seterrorType(response.SdMErrortype)
             
         })
         .catch(error => {
           console.error('Error:', error);
           setLoading(false);
         });
        
       }
       useEffect(() => {

        fetchData();
      
      }, []);
    const [formData, setFormData] = useState({
      SymptomName: '',
      SdMErrortype:{},
      ErrorTypeId:'',
        IsActive: 'Active',
      });
  const [loading, setLoading] = useState(true);
    
      const statusOptions = [
        { value: 'Active', viewValue: 'Active' },
        { value: 'Inactive', viewValue: 'Inactive' },
      ];
      const [userData, setUserData] = useState(null);
      useEffect(() => {
        const storedData = localStorage.getItem('userDetail');
    
        if (storedData) {
          const parsedData = JSON.parse(storedData);
    
          setUserData(parsedData);
        }
      }, []);
      const handleChange = (e) => {
        const { name, value } = e.target;
        if(name === 'ErrorTypeId'){
          const errorTypeById = errorType.find((type) => type.Id === value);
          setFormData((prevData) => ({
            ...prevData,
            "SdMErrortype":{
              Id:errorTypeById.Id,
              ErrorTypeName:errorTypeById.ErrorTypeName
            }
          }));
          
        }
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
      function getCurrentDateTime() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
        const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');
      
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
      }
      const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission
        const updatedFormData = {
            ...formData,
            CreatedOn: getCurrentDateTime(),
            CreatedBy:userData.Id,
            IsActive:formData.IsActive == "Active" ? true : false,
          };
          setFormData(updatedFormData);
          try {
            const res = await ApiService.post("sdmerrorsymptom", updatedFormData);
            if (res) {
                handleCancel()
            }
          } catch (error) {
            console.error('Error updating toggle state:', error);
          }
        // console.log('Form submitted:', updatedFormData);
      };
    
      const handleCancel = () => {
        // Handle cancel action
        onCancel();
      };
     
      
  return (
    <div className='mainContainer'>
     <div class="dis-flex headTitle">
       <img src={leftArrow} alt="" onClick={handleCancel} className='cursor'/>
        <p>Create Error Symptom</p>
    </div>
    <form onSubmit={handleSubmit} className='m-t-24'>
      <div className="Content">
        <div className="displayFlex m-b">
          <div className="example-container">
            <TextField
              label="Symptom Name"
              name="SymptomName"
              value={formData.SymptomName}
              onChange={handleChange}
              required
              maxLength={50}
              className='commonInput'
              InputProps={{
                endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className='Icon-style' />
                </Tooltip>
                ),
              }}
            />
          </div>
          <div className="example-container">
            <FormControl>
              <InputLabel>Error Type</InputLabel>
              <Select
                name="ErrorTypeId"
                value={formData.ErrorTypeId}
                onChange={handleChange}
                required
              className='commonInput'
              >
                {errorType !== null ? (
      errorType.map((type) => (
        <MenuItem key={type.Id} value={type.Id}>{type.ErrorTypeName}</MenuItem>
      ))
    ) : (
      <MenuItem value="">Loading...</MenuItem>
    )}
              </Select>
            </FormControl>
          </div>
          <div className="example-container">
          <FormControl >
            <InputLabel>Status</InputLabel>
            <Select
              name="IsActive"
              value={formData.IsActive}
              onChange={handleChange}
              required
              className='commonInput'
            >
              {statusOptions.map((status) => (
                <MenuItem key={status.value} value={status.value}>{status.viewValue}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        </div>
      </div>
      <div className="submitButton">
        <Button type="submit"  className="btn">Save</Button>
        <Button className="cancelBtn" onClick={handleCancel}>Cancel</Button>
      </div>
    </form>
    </div>
  );
};

export default ErrorSymptomsAdd;
