import React, { useState, useEffect } from "react";
import leftArrow from "./../../../../src/images/left-arrow.svg";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import ApiService from "../../../Services/ApiService";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { useSnackbar } from 'notistack';
const FaqsEdit = ({ onCancel, dataSource }) => {
  const { enqueueSnackbar } = useSnackbar();
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    [{ 'image': '' }], // Add image button to toolbar
    ['clean']
  ];

  const [category, setcategory] = useState(null);
  const [categoryData, setcategoryData] = useState()
  const [editor, setEditor] = useState(true); // State variable for textarea value
  const onSlideToggleChange = (even)=>{
    setEditor(!editor);
  }
  const fetchData = async () => {
    const filterData = {
     "Index": 0,
     "Limit": 100000,
     "OrderByTable": "",
     "OrderByField": "",
     "Sort": "",
     "Aggregator": "",
     "Conditions": [
       
     ]
   }
         const response = ApiService.post('sdmcategories/list', filterData)
         .then(response => {
          setcategory(response.SdMCategories)
             
         })
         .catch(error => {
           console.error('Error:', error);
           setLoading(false);
         });
        
       }
       useEffect(() => {

        fetchData();
      
      }, []);
  const [formData, setFormData] = useState({
    Category: dataSource.Category,
      Question:dataSource.Question,
      Answer:dataSource.Answer,
    IsActive: dataSource.IsActive == true ? "Active" : "Inactive",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {}, []);
  const statusOptions = [
    { value: "Active", viewValue: "Active" },
    { value: "Inactive", viewValue: "Inactive" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleChange1=(content)=>{
    // const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      "Answer": content,
    }));
  }
  function getCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const storedData = localStorage.getItem("userDetail");

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      setUserData(parsedData);
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Form submitted:", formData);
    const updatedFormData = {
      ...formData,
      Id: dataSource.Id,
      CreatedOn: dataSource.CreatedOn,
      CreatedBy: dataSource.CreatedBy,
      ModifiedBy: userData.Id,
      ModifiedOn: getCurrentDateTime(),
      IsActive: formData.IsActive == "Active" ? true : false,
    };
    setFormData(updatedFormData);
    try {
      const res = await ApiService.put("sdmfaqs", updatedFormData);
      if (res) {
        if (res.Error == false) {
          handleCancel();
          enqueueSnackbar('Edited Successfully!',{ variant: 'success', autoHideDuration: 3000 });
        } else {
            enqueueSnackbar(res.Message,{ variant: 'warning', autoHideDuration: 3000 });   
        }
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
    // console.log("Form submitted:", updatedFormData);
  };

  const handleCancel = () => {
    // Handle cancel action
    onCancel();
  };
  return (
    <div className="mainContainer">
      <div className="dis-flex headTitle">
        <img src={leftArrow} alt="" onClick={handleCancel} className="cursor" />
        <p>Edit Faqs</p>
      </div>
      <form onSubmit={handleSubmit} className="m-t-24">
      <div className="Content">
        <div className="displayFlex m-b">
        <div className="example-container">
            <FormControl>
              <InputLabel>Category Name</InputLabel>
              <Select
                name="Category"
                value={formData.Category}
                onChange={handleChange}
                required
              className='commonInput'
              >
                {category !== null ? (
      category.map((type) => (
        <MenuItem key={type.Id} value={type.Id}>{type.CategoryName}</MenuItem>
      ))
    ) : (
      <MenuItem value="">Loading...</MenuItem>
    )}
              </Select>
            </FormControl>
          </div>
          <div className="example-container">
            <TextField
              label="Question"
              name="Question"
              value={formData.Question}
              onChange={handleChange}
              required
              maxLength={50}
              className='commonInput'
              InputProps={{
                endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className='Icon-style' />
                </Tooltip>
                ),
              }}
            />
          </div>
    
        </div>
        <div className="example-container m-b">
        <div className="checkbox-lable m-24">
        Answer
      </div>
          <label className="switch">
            <input
              className="switch-input"
              type="checkbox"
              checked={editor}
              onChange={(event) => onSlideToggleChange(event)}
            />
            <span className="switch-label" data-on="Editor" data-off="HTML"></span>
            <span className="switch-handle"></span>
          </label>
          {!editor &&  <textarea
                            name="ContentHtml"
                            required
                            className="multiSelectWidth"
                            placeholder="Write something"
                            value={formData.Answer} 
                            style={{ 'minHeight': '263.5px', 'background':'transparent','border': '1px solid #ccc', 'margin-bottom':'-6px' }}
                            onChange={(event)=>handleChange1(event.target.value)}
                        />}
          {editor && <ReactQuill  className="quill-editor multiSelectWidth"  value={formData.Answer} onChange={handleChange1} 
           modules={{ toolbar: toolbarOptions }}
        placeholder={"Write something"} />}
            </div>
            <div className="example-container m-b">
          <FormControl >
            <InputLabel>Status</InputLabel>
            <Select
              name="IsActive"
              value={formData.IsActive}
              onChange={handleChange}
              required
              className='commonInput'
            >
              {statusOptions.map((status) => (
                <MenuItem key={status.value} value={status.value}>{status.viewValue}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
        <div className="submitButton">
          <Button type="submit" className="btn">
            Update
          </Button>
          <Button className="cancelBtn" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FaqsEdit;
