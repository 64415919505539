import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import Quill from 'quill';
import * as QuillTableUI from 'quill-table-ui'
import 'quill/dist/quill.snow.css'; // Import Quill styles
import 'quill-table-ui/dist/index.css';

const cusIcons = {
  customIcon: `
    <svg fill="#666666" width="16px" height="16px" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path d="M2,6 L6,6 L6,2 L2,2 L2,6 Z M2,8 L2,12 L6,12 L6,8 L2,8 Z M12,6 L12,2 L8,2 L8,6 L12,6 Z M12,8 L8,8 L8,12 L12,12 L12,8 Z M2,0 L12,0 C13.1045695,0 14,0.8954305 14,2 L14,12 C14,13.1045695 13.1045695,14 12,14 L2,14 C0.8954305,14 0,13.1045695 0,12 L0,2 C0,0.8954305 0.8954305,0 2,0 Z"/>
    </svg>
  `
};
// Editor is an uncontrolled React component
const Editor = forwardRef(
  ({ readOnly, defaultValue, onTextChange, onSelectionChange, placeHolder }, ref) => {
    const containerRef = useRef(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    });

    useEffect(() => {
      ref?.current?.enable(!readOnly);
    }, [ref, readOnly]);

    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div'),
      );

      Quill.register({
        'modules/tableUI': QuillTableUI.default
      }, true)

      var icons = Quill.import('ui/icons');
      icons['customTable'] = cusIcons.customIcon;

      const quill = new Quill(editorContainer, {
        theme: 'snow',
        placeholder: placeHolder,
        modules: {
          table: true,
          tableUI: true,
          toolbar:{
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],
              [{ 'indent': '-1'}, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'font': [] }],
              [{ 'align': [] }],
              [{ 'image': '' }],
              ['customTable'],
              ['clean']
            ],
            handlers: {
              customTable: function() {
                // Handle custom icon click event
                insertTable();
              }
            }
          },
          clipboard: {
            matchVisual: false // Ensure HTML tags are preserved
          },
        },
      });

      // Event handler for inserting a table
      const insertTable = () => {
        const tableModule = quill.getModule('table');
        tableModule.insertTable(2, 2); // Insert a 2x2 table
      };

      // Example button click handler
      const handleButtonClick = () => {
        insertTable();
      };

      ref.current = quill;

      if (defaultValueRef.current) {
        quill.clipboard.dangerouslyPasteHTML(defaultValueRef.current)
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        onTextChangeRef.current?.(quill.getSemanticHTML());
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      return () => {
        ref.current = null;
        container.innerHTML = '';
      };
    }, [ref]);

    return (<div>
        <div id="editor-wrapper" ref={containerRef}></div>
      </div>
      );
  },
);

Editor.displayName = 'Editor';

export default Editor;