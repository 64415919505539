import React, { useRef, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from '@mui/icons-material/Close';
import leftArrow from './../../../../src/images/left-arrow.svg';
import ApiService from '../../../Services/ApiService';
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import ReactQuill from "react-quill";
import Editor from '../../../components/quillEditor/quillEditor'
import { Loader } from '../../Loader';

const EditContentDialog = ({ open, onClose,type, EditData,value,keyValue,pageKey, languageValues }) => {
  const { enqueueSnackbar } = useSnackbar();
    const [contentValue, setContentValue] = useState(value? value: ''); // State variable for textarea value
    const [contentKeyValue, setContentKeyValue] = useState(keyValue ? keyValue : ''); // State variable for key value
    const [languageValue, setlanguageValues] = useState(languageValues ? languageValues : ''); // State variable for key value
    const [contentPageKey, setcontentPageKey] = useState(pageKey ? pageKey : ''); // State variable for key value
    const [editor, setEditor] = useState(false); // State variable for textarea value
    const [selectedType, setSelectedType ] = useState(type)
    const [loading, setLoading] = useState(false);
    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        [{ 'image': '' }], // Add image button to toolbar
        ['clean']
      ];
      const quillRef = useRef();
 const onSlideToggleChange = (even)=>{
    setEditor(!editor)
    // setContentValue(value? value: '');
 }
    useEffect(() => {
        if (EditData) {
            setContentValue(value);
            setContentKeyValue(keyValue);
            setlanguageValues(languageValues)
        }
        // console.log(EditData,languageValues,value,keyValue)
    }, [EditData]);

    const handleValueChange = (e) => {
        // // console.log(e);
        setContentValue(e);
    };

    const updateValue = async () => {
        try {
            setLoading(true);
            const url = type == 'mobile' ? 'updatemobilejsoncontent' : 'updatejsoncontent'
            // console.log(contentValue);
            const res = await ApiService.CustomerApiput("sdmcontentpages/"+url, {
                key: contentKeyValue,
                value: contentValue,
                "pageKey": contentPageKey
            }, languageValue);
            if (res) {
                enqueueSnackbar("Value Updated Successfully!", {
                    variant: "success", autoHideDuration: 3000
                  });
                  setLoading(false);
                onClose()
            }
        } catch (error) {
            setLoading(false);
            console.error("Error updating toggle state:", error);
        }
    };

    return (
    <>{loading && <Loader />}
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                <div className="dis-flex headTitle addbanner">
                    <img src={leftArrow} alt="" onClick={onClose} className='cursor' />
                    <p>Edit Value</p>
                </div>
              {type != 'mobile' &&  <label className="switch">
<input
  className="switch-input"
  type="checkbox"
  checked={editor}
  onChange={(event) => onSlideToggleChange(event)}
/>
<span className="switch-label" data-on="editor" data-off="text"></span>
<span className="switch-handle"></span>
</label>}
            </DialogTitle>
            <DialogContent className='pb-0'>
                <div className="Content">
                    <div className="textareaCls">
                        <label className="post">
                            Value*
                        </label>
                      {!editor &&  <textarea
                            name="Value"
                            required
                            className="multiSelectWidth"
                            value={contentValue} 
                            style={{ minHeight: '64px' }}
                            onChange={(event)=>handleValueChange(event.target.value)}
                        />}
                          {/* {editor && <ReactQuill
              theme="snow"
              className="quill-editor multiSelectWidth"
              modules={{ toolbar: toolbarOptions }}
              value={contentValue}
              onChange={(event)=>editor ? handleValueChange(event) : null}
            //   modules={{
            //     toolbar: toolbarOptions,
            //   }}
              placeholder={"Write something"}
            />} */}
            {editor && <Editor
            ref={quillRef}
            defaultValue={contentValue}
            onTextChange={(event)=>editor ? handleValueChange(event) : null}
            placeHolder={"Write something"}
            />}
                    </div>
                </div>
            </DialogContent>
            <DialogActions className="submitButton m-16">
                <Button type="submit" className="btn" onClick={updateValue}>
                    Update
                </Button>
                <Button className="cancelBtn" onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
        </>
    );
};

export default EditContentDialog;