import React, { useState, useEffect } from "react";
import leftArrow from "./../../../../src/images/left-arrow.svg";
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import ApiService from "../../../Services/ApiService";
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { useSnackbar } from 'notistack';
// import Chip from "@mui/material/Chip";
// import Autocomplete from "@mui/material/Autocomplete";
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import Chip from '@material-ui/core/Chip';
// import { TextField as MuiTextField } from '@mui/material/TextField';
import { TextField as MaterialUITextField } from "@material-ui/core";
import { TextField as MuiTextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import { Loader } from "../../Loader";
const BoquetPageAdd = ({ onCancel }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [userData, setUserData] = useState(null);
  const [selectedNetworkChannels, setSelectedNetworkChannels] = useState({});
  const [selectedOption, setSelectedOption] = useState('Sundirect');
  const handleChangesnetwork = (networkGenreId, value, genreName) => {
    setSelectedNetworkChannels((prevSelectedChannels) => ({
      ...prevSelectedChannels,
      [networkGenreId]: value,
    }));
  };
  const [formData, setFormData] = useState({
    BouquetName: "",
    SystemName: "",
    Poid: 0,
    Description: "",
    BouquetSmsCode: "",
    Price:'',
    IsSdBouquet:true,
    IsBcBouquet:false,
    ShowInFTA:false,
    IsActive: "Active",
  });
  const [ListArray, setListArray] = useState([]);
  const [typeArray, setType] = useState([]);
  const [Networkloading, setNetworkLoading] = useState();
  const [languageArray, setLanguage] = useState([]);
  const [NetworkArray, setNetwork] = useState([]);
  const [BoxtypeArray, setBoxtype] = useState([]);
  const [isTypeSelected, setIsTypeSelected] = useState(false);
  const [isTypeSelectedNetwork, setIsTypeSelectedNetwork] = useState(false);
  const [isAddonsSelected, setIsAddonsSelected] = useState(false);
  const [isNetworkSelected, setIsNetworkSelected] = useState(false);
  const [value, setValue] = React.useState([]);
  const [languagevalue, setlanguageValue] = React.useState();
  const [networkvalue, setnetworkValue] = React.useState();
  const [selectedChannels, setSelectedChannels] = useState({});
  const [selectedGenre, setSelectedGenre] = useState([]);
  const [NetworkAndGenre, setListNetworkGenre] = useState([]);
  const [checkboxVali, setcheckboxVali] = useState(false);
  const [selectedGenres, setSelectedGenres] = useState({});
  const [ChannelArray, setChannel] = useState([]);
  const [NetworkChannelArray, setNetworkChannel] = useState([]);

  const [Channelvalue, setChannelValue] = React.useState([]);
  const [NetworkChannelvalue, setNetworkChannelValue] = React.useState([]);

  const handleChangesnet = (genreId, value, genreName) => {
    setSelectedGenres((prevSelectedGenres) => ({
      ...prevSelectedGenres,
      [genreId]: value,
    }));
  };
  useEffect(() => {}, [networkvalue]);
  useEffect(() => {
    // getGenreList();
    getChannelList();
  
    getLanguageList();
    getNetworkList();
    const storedData = localStorage.getItem("userDetail");

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      setUserData(parsedData);
    }
  }, []);
  const getChannelList = async () => {
    try {
      const res = await ApiService.getAll("common/sdchannellist");
      if (res) {
        setChannel(res);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const [networkBasedChannels, setnetworkBasedChannels] = useState([]);
  const [networkChannelData, setnetworkChannelData] = useState([]);
  const getchannelListBasedOnNetwork = async (params) => {
    try {
      const res = await ApiService.getOne("sdmnetwork", params);
      if (res) {
        setnetworkBasedChannels(res.NetworkGenres);
        // // console.log(networkBasedChannels)
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  useEffect(() => {
    // // console.log(networkBasedChannels);
    let networkChannelDatas = addGenreIdToChannels(networkBasedChannels);
    setnetworkChannelData(networkChannelDatas);
    // console.log(networkChannelData);
  }, [networkBasedChannels]);
  const addGenreIdToChannels = (networkBasedChannels) => {
    return networkBasedChannels.map((item) => {
      const channelsWithGenreId = item.NetworkGenreChannel.map((channel) => ({
        ...channel,
        GenreId: item.GenreId,
        GenreName: item.GenreName
      }));
      return {
        ...item,
        NetworkGenreChannel: channelsWithGenreId,
      };
    });
  };

  const getLanguageList = async () => {
    const typeRequest = {
      Index: 0,
      Limit: 10000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "",
      Conditions: [],
    };
    try {
      setLoading(true);
      const res = await ApiService.post("sdmlanguage/list", typeRequest);
      if (res) {
        setLanguage(res);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const getNetworkList = async () => {
    const typeRequest = {
      Index: 0,
      Limit: 10000000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "",
      Conditions: [],
    };
    try {
      setNetworkLoading(true)
      const res = await ApiService.post("sdmnetwork/list/v1", typeRequest);
      if (res) {
        setNetwork(res);
        setNetworkLoading(false)
      }
    } catch (error) {
      setNetworkLoading(false)
      console.error("Error updating toggle state:", error);
    }
  };
  useEffect(() => {}, [userData]);

  const [loading, setLoading] = useState();

  const statusOptions = [
    { value: "Active", viewValue: "Active" },
    { value: "Inactive", viewValue: "Inactive" },
  ];
  const InactiveDays = [
    { value: "0-30", viewValue: "0-30 Days" },
    { value: "31-60", viewValue: "31-60 Days" },
    { value: "61-90", viewValue: "61-90 Days" },
    { value: "90+", viewValue: "90+ Days" },
  ];
const radiohandlechange = (e) =>{
  setSelectedOption(e.target.value)
  setFormData((prevData) => ({
    ...prevData,
    IsSdBouquet: e.target.value === 'Sundirect' ? true : false,
    IsBcBouquet: e.target.value === 'boradcaster' ? true : false,
  }));
  if(e.target.value === 'Sundirect'){
    setnetworkValue();
    setnetworkChannelData([]);
      setNetworkChannelValue([]);
  }
  if(e.target.value === 'boradcaster'){
    setChannelValue([])
  }
  // console.log(selectedOption)
  // console.log(e.target)
}
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "ShowInFTA") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: e.target.checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  function getCurrentDateTime() {
    let specificValue = [];
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission
    if (!formData.IsSdBouquet && !formData.IsBcBouquet) {
      setcheckboxVali(true);
    } else {
     // console.log('............',NetworkChannelvalue)
     const genreDetails = NetworkChannelvalue.reduce((acc, curr) => {
      const existingGenre = acc.find(item => item.GenreId === curr.GenreId);
      if (existingGenre) {
          existingGenre.channelsList.push({ ChannelId: curr.ChannelId });
      } else {
          acc.push({
              GenreId: curr.GenreId,
              channelsList: [{ ChannelId: curr.ChannelId }]
          });
      }
      return acc;
  }, []);
  
      const getLanguageId =languagevalue?.Id ? [languagevalue.Id] :[];
      const getNetworkId = networkvalue ? [networkvalue.Id] : [];
      const networkwithGenre = Object.entries(selectedNetworkChannels).map(
        ([key, value]) => {
          const genreDetails = {
            GenreId: parseInt(key),
            channelsList: value.map((channel) => ({
              ChannelId: channel.ChannelId,
            })),
          };
          return genreDetails;
        }
      );

      const combined = [];
      Object.entries(selectedChannels).forEach(([genreId, channels]) => {
        const genreName = selectedGenre[genreId];
        const networkGenre = {
          GenreId: parseInt(genreId),
          channelsList: channels.map((channel) => ({
            GenreId: channel.GenreId,
            ChannelId: channel.Id,
          })),
        };
        combined.push(networkGenre);
      });
      setListNetworkGenre(combined);
      // channelvalues
      const transformedArray = Channelvalue.reduce((acc, curr) => {
        // Check if the genre exists in the accumulator array
        const existingGenre = acc.find((item) => item.GenreId === curr.GenreId);
        if (existingGenre) {
          // If the genre exists, add the channel to its NetworkGenreChannel array
          existingGenre.channelsList.push({
            ChannelId: curr.ChannelId,
          });
        } else {
          // If the genre doesn't exist, create a new entry for it in the accumulator array
          acc.push({
            GenreId: curr.GenreId,
            channelsList: [
              {
                ChannelId: curr.ChannelId,
              },
            ],
          });
        }
        return acc;
      }, []);

      const updatedFormData = {
        ...formData,
        CreatedOn: getCurrentDateTime(),
        CreatedBy: userData.Id,
        IsActive: formData.IsActive == "Active" ? true : false,
        BouquetStatus: "pendingReview",
        genreDetails:  formData.IsBcBouquet ? genreDetails : transformedArray,
        // genreDetails: transformedArray,
        LanguageId: getLanguageId,
        NetworkId: getNetworkId,
      };
      setFormData(updatedFormData);
      // console.log(selectedNetworkChannels);
      // console.log(updatedFormData);
      try {
        const res = await ApiService.post("sdmbouquet", updatedFormData);
        if (res) {
          if (res.Error == false) {
            handleCancel();
            enqueueSnackbar('Added Successfully!',{ variant: 'success', autoHideDuration: 3000 });
        } else {
            enqueueSnackbar(res.Message,{ variant: 'warning', autoHideDuration: 3000 });   
        }
        }
      } catch (error) {
        console.error("Error updating toggle state:", error);
      }
    }
  };

  const handleCancel = () => {
    // Handle cancel action
    onCancel();
  };
  const handleKeyDown = (e) => {
    const keynum = e.which || e.keyCode;
  
    // Allow backspace, tab, and enter keys
    if (keynum === 8 || keynum === 9 || keynum === 13 || (e.ctrlKey && (keynum === 65 || keynum === 86 || keynum === 88))) {
      return;
    }
  
    // Regular expression to match only numeric characters and a single dot
    const isValidInput = /^[0-9.]+$/.test(e.key);

    // Prevent input if the pressed key is not valid
    if (!isValidInput) {
      e.preventDefault();
    }

    // Prevent input of more than one dot
    const value = e.target.value;
    if ((keynum === 46 || keynum === 190) && value.includes('.')) {
        e.preventDefault();
    }
};
const handlePaste = (e) => {
  const pastedData = e.clipboardData.getData('text');

  // Regular expression to match numeric characters and at most one dot
  const isValidInput = /^[0-9]*(\.[0-9]+)?$/.test(pastedData);

  // Prevent paste if the input is invalid
  if (!isValidInput) {
      e.preventDefault();
  }
};
  useEffect(() => {}, [value, languagevalue, networkvalue]);
  // dynamic fields
  const New = { inputProps: { "aria-label": "New" } };
  const Existing = { inputProps: { "aria-label": "Existing" } };
  const [inputGroups, setInputGroups] = useState([
    
  ]);

  const addInputGroup = () => {
    setInputGroups([
      ...inputGroups,
      {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
        input8: "",
        input9: "",
        input10:"",
      },
    ]);
  };

  const removeInputGroup = (index) => {
    const updatedGroups = [...inputGroups];
    updatedGroups.splice(index, 1);
    setInputGroups(updatedGroups);
  };

  const handleInputChange = (index, fieldName, value) => {
    const updatedGroups = [...inputGroups];
    updatedGroups[index][fieldName] = value;
    setInputGroups(updatedGroups);
  };
  const isInputGroupValidNew = (group) => {
    // Check if any of the required fields are empty
    return (
      group.input1 !== "" &&
      group.input2 !== "" &&
      group.input3 !== "" &&
      group.input4 !== "" &&
      group.input5 !== "" &&
      group.input6 !== "" &&
      group.input7 !== "" &&
      group.input8 !== "" &&
      group.input9 !== "" &&
      group.input10 !== ""
    );
  };

  const [inputGroupExisting, setInputGroupsExisting] = useState([]);

  const addInputGroupExisting = () => {
    setInputGroupsExisting([
      ...inputGroupExisting,
      {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
        input8: "",
        input9: "",
      },
    ]);
  };

  const removeInputGroupExisting = (index) => {
    const updatedGroups = [...inputGroupExisting];
    updatedGroups.splice(index, 1);
    setInputGroupsExisting(updatedGroups);
  };

  const handleInputChangeExisting = (index, fieldName, value) => {
    const updatedGroups = [...inputGroupExisting];
    updatedGroups[index][fieldName] = value;
    setInputGroupsExisting(updatedGroups);
  };
  const isInputGroupValid = (group) => {
    // Check if any of the required fields are empty
    return (
      group.input1 !== "" &&
      group.input2 !== "" &&
      group.input3 !== "" &&
      group.input4 !== "" &&
      group.input5 !== "" &&
      group.input6 !== "" &&
      group.input7 !== "" &&
      group.input8 !== "" &&
      group.input9 !== ""
    );
  };
 
  const handlenetwork = (newValue) => {
    setNetworkChannelValue([]);
    if(!newValue){
      setnetworkChannelData([])
    }
  };
  return (
    <div>
      {Networkloading && <Loader />}
      {loading && <Loader />}
      <div className="mainContainer">
        <div class="dis-flex headTitle">
          <img
            src={leftArrow}
            alt=""
            onClick={handleCancel}
            className="cursor"
          />
          <p>Create Bouquet</p>
        </div>
        <form onSubmit={handleSubmit} className="m-t-24">
          <div className="Content">
            <div className="displayFlex m-b">
              <div className="example-container">
                <MaterialUITextField
                  label="Bouquet Name"
                  name="BouquetName"
                  value={formData.BouquetName}
                  onChange={handleChange}
                  required
                  maxLength={50}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              <div className="example-container">
                <MaterialUITextField
                  label="System Name"
                  name="SystemName"
                  value={formData.SystemName}
                  onChange={handleChange}
                  required
                  maxLength={50}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              <div className="example-container">
                <MaterialUITextField
                  label="Poid"
                  name="Poid"
                  value={formData.Poid}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                  maxLength={25}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports numbers upto 25 chars.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="displayFlex m-b">
              <div className="example-container">
                <MaterialUITextField
                  label="Bouquet Sms Code"
                  name="BouquetSmsCode"
                  value={formData.BouquetSmsCode}
                  onChange={handleChange}
                  required
                  maxLength={50}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              <div className="example-container">
                <MaterialUITextField
                  label="Price"
                  name="Price"
                  value={formData.Price}
                  onChange={handleChange}
                  required
                  maxLength={50}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              {languageArray.SdMLanguage &&
                languageArray.SdMLanguage.length > 0 && (
                  <div className="exmple-container">
                    <div className="w254">
                      <Autocomplete
                        id="fixed-tags-demo3"
                        value={languagevalue}
                        onChange={(event, newValue) => {
                          setlanguageValue(newValue);
                          // console.log(newValue);
                        }}
                        options={languageArray.SdMLanguage.filter(
                          (option) =>
                            !value.some((selected) => selected.Id === option.Id)
                        )}
                        getOptionLabel={(option) => option.LanguageName}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              label={option.LanguageName}
                              {...getTagProps({ index })}
                              className="chipsStyle"
                              // disabled={ListArray.indexOf(option) !== -1}
                            />
                          ))
                        }
                        // style={{ width: 858, marginBottom: 24 }}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label="Language"
                            placeholder="Language"
                            variant="standard"
                            // required
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
            </div>
            <div className="example-container m-b">
              <MaterialUITextField
                className="commonInput multiSelectWidth"
                label="Bouquet Description"
                name="Description"
                value={formData.Description}
                onChange={handleChange}
                multiline
                maxLength={100}
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      title="Supports alphabets, numbers, <Space> & - and + upto 100 chars."
                      placement="top"
                    >
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="displayFlex">
            <div className="exmple-container">
      <div className="m-24">
        <p className="post">Bouquet Type</p>
        <RadioGroup
          name="bouquet"
          value={selectedOption}
          onChange={radiohandlechange}
          row
        >
          <FormControlLabel
            value="Sundirect"
            control={<Radio sx={{ color: "#EE3823", '&.Mui-checked': { color: "#EE3823" } }} />}
            label="Sundirect"
            labelPlacement="end"
          />
          <FormControlLabel
            value="boradcaster"
            control={<Radio sx={{ color: "#EE3823", '&.Mui-checked': { color: "#EE3823" } }} />}
            label="Broadcaster"
            labelPlacement="end"
          />
        </RadioGroup>
      </div>
    </div>
    {/* <div className="example-container">
            <div className="commonInput">
              <Checkbox
                checked={formData.ShowInFTA}
                name="ShowInFTA"
                onChange={handleChange}
                sx={{
                  color: "#EE3823",
                  "&.Mui-checked": {
                    color: "#EE3823",
                  },
                }}
              />
             Show In FTA
              </div>
            </div> */}
                </div>
          
    {formData.IsBcBouquet && (
        <div>
        {NetworkArray.SdMNetwork &&
          NetworkArray.SdMNetwork.length > 0 && (
            <div className="exmple-container m-b">
              <div>
                <Autocomplete
                  id="fixed-tags-demo3"
                  value={networkvalue}
                  onChange={(event, newValue) => {
                    setnetworkValue(newValue);
                    setIsTypeSelectedNetwork(
                      newValue ? newValue.length > 0 : false
                    );
                    getchannelListBasedOnNetwork(
                      newValue ? newValue.Id : 0
                    );
                    // console.log(newValue);
                    handlenetwork(newValue);
                  }}
                  options={NetworkArray.SdMNetwork.filter(
                    (option) =>
                      !value.some(
                        (selected) => selected.Id === option.Id
                      )
                  )}
                  getOptionLabel={(option) => option.NetworkName}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        label={option.NetworkName}
                        {...getTagProps({ index })}
                        className="chipsStyle"
                        // disabled={ListArray.indexOf(option) !== -1}
                      />
                    ))
                  }
                  style={{ width: 858, marginBottom: 24 }}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      label="Network"
                      placeholder="Network"
                      required={!isTypeSelectedNetwork}
                      variant="standard"
                    />
                  )}
                />
              </div>
            </div>
          )}
           {networkChannelData && (
              <div className="exmple-container m-b">
                <div className="commonInput">
                  <Autocomplete
                    multiple
                    id="fixed-tags-demo3"
                    value={NetworkChannelvalue}
                    onChange={(event, newValue) => {
                      setNetworkChannelValue(newValue);
                    }}
                    options={networkChannelData.flatMap(
                      (item) => item.NetworkGenreChannel
                    )}
                    getOptionLabel={(option) => (option.ChannelName+"("+option.GenreName+")")}
                    getOptionDisabled={(option) =>
                      NetworkChannelvalue.some(
                        (selected) =>
                          selected.ChannelId === option.ChannelId && selected.GenreId === option.GenreId
                      )
                    }
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={(option.ChannelName+"("+option.GenreName+")")}
                          {...getTagProps({ index })}
                          className="chipsStyle"
                          // disabled={ListArray.indexOf(option) !== -1}
                        />
                      ))
                    }
                    style={{ width: 858, marginBottom: 24 }}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        label="Network Based Channels"
                        variant="standard"
                        placeholder="Please select network"
                      />
                    )}
                  />
                </div>
              </div>
            )}
      </div>
      
    )}
    {formData.IsSdBouquet && (
      <div>
         {!isNetworkSelected && ChannelArray && ChannelArray.length > 0 && (
              <div className="exmple-container m-b">
                <div>
                  <Autocomplete
                    multiple
                    id="fixed-tags-demo3"
                    value={Channelvalue}
                    onChange={(event, newValue) => {
                      setChannelValue(newValue);
                    }}
                    options={ChannelArray.filter(
                      (option) =>
                        !value.some((selected) => selected.Id === option.Id)
                    )}
                    getOptionDisabled={(option) =>
                      Channelvalue.some(
                        (selected) =>
                          selected.ChannelId === option.ChannelId && selected.GenreId === option.GenreId
                      )
                    }
                    getOptionLabel={(option) => (option.ChannelName+"("+option.GenreName+")")}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={(option.ChannelName+"("+option.GenreName+")")}
                          {...getTagProps({ index })}
                          className="chipsStyle"
                          // disabled={ListArray.indexOf(option) !== -1}
                        />
                      ))
                    }
                    style={{ width: 858, marginBottom: 24 }}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        label="Channels"
                        placeholder="Channels"
                        variant="standard"
                      />
                    )}
                  />
                </div>
              </div>
            )}
      </div>
    )}
            <div className="example-container m-b">
              <FormControl>
                <InputLabel>Status</InputLabel>
                <Select
                  name="IsActive"
                  value={formData.IsActive}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {statusOptions.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.viewValue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="submitButton">
            <Button type="submit" className="btn">
              Save
            </Button>
            <Button className="cancelBtn" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BoquetPageAdd;
