import React, { useState, useEffect } from "react";
import leftArrow from "./../../../../src/images/left-arrow.svg";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Table, TableBody, TableCell, TableHead, TableRow, Paper, TableSortLabel, TablePagination
} from "@material-ui/core";
import deleteIcon from "./../../../../src/images/trash-01.svg";
import editIcon from "./../../../../src/images/edit-01.svg";
import searchIcon from "./../../../../src/images/search-lg.svg";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import ApiService from "../../../Services/ApiService";
import ReactQuill from "react-quill";
import plusIcon from "./../../../../src/images/LeadingIcon.svg";
import AddBannerDialog from "./AddBannerDialog";
import EditContentDialog from "./EditContentDialog";
import { Loader } from '../../Loader';

const ContentPageEdit = ({ onCancel, dataSource }) => {


  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    [{ image: "" }], // Add image button to toolbar
    ["clean"],
  ];
  const [categoryData, setcategoryData] = useState(null);
  const [imageError, setimageError] = useState(null);
  const [imageFile, setimageFile] = useState(null); // State for icon file
  const [templateData, settemplateData] = useState(null);
  const [BannerDialogOpen, setBannerDialogOpen] = useState(false);
  const [EditDialogOpen, setEditDialogOpen] = useState(false);

  const [search, setSearch] = useState("");
  const [BannerformDataList, setBannerFormDataList] = useState([]);
  const [BannerEditData, setBannerEditData] = useState(null);
  const [ContentEditData, setContentEditData] = useState(null);
  const [editKey, setEditKey] = useState(null);
  const [editvalue, setEditValue] = useState(null);
  const [droppedItem, setDroppedItem] = useState(null); // State for droppedItem
  const [oldPriority, setOldPriority] = useState(null);
  const [draggedItem, setDraggedItem] = useState(null);
  const [contentList, setcontentList] = useState([]);
  const [contentResponse, setContentResponse]= useState([]);
  const [filterData, setFilterData] = useState({
    Index: 0,
    Limit: 15,
    OrderByTable: '',
    OrderByField: 'Priority',
    Sort: '',
    Aggregator: 'OR',
    Conditions: [
      {
        TableName: 'SdMLanguage',
        FieldName: 'LanguageName',
        Operator: 'like',
        FieldValue: ''
      },
      
    ]
  });
  const fetchData = async () => {
    const response = await ApiService.getAll("common/template");
    settemplateData(response);
  };
  const fetchCategoryData = async () => {
    const filterData = {
      Index: 0,
      Limit: 100000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "",
      Conditions: [],
    };
    const response = await ApiService.post(
      "sdmcategories/list",
      filterData
    );
    setcategoryData(response.SdMCategories);
  };

  useEffect(() => {
    getContentList("en");
    fetchCategoryData();
    fetchData();
    // console.log(dataSource);
    getLanguageList()
  }, []);
  const [formData, setFormData] = useState({
    TemplateId: "",
    PageKey: "",
    ContentTitle: "",
    Content: "",
    ImageUrl: "",
    Notes: "",
    Widges1: "",
    Type1: "",
    Widges2: "",
    Type2: "",
    MetaPageTitle: "",
    MetaKeywords: "",
    MetaDescription: "",
    Status: "pendingReview",
    IsActive: "Active",
  });
  const [loading, setLoading] = useState(false);
  const [languageValues,setlanguageValues]=useState('en')
  const statusOptions = [
    { value: "Active", viewValue: "Active" },
    { value: "Inactive", viewValue: "Inactive" },
  ];
  const reviewOptions = [
    { value: "pendingReview", viewValue: "Pending Review" },
  ];
  const TypesOptions = [
    { value: "logoGrid", viewValue: "Logo Grid" },
    { value: "slider", viewValue: "Slider" },
    { value: "grid", viewValue: "Grid" },
  ];
  const selectLanguage = [
    { value: "English", viewValue: "en" },
    { value: "Tamil", viewValue: "ta" },
    { value: "Denmark", viewValue: "de-DE" },
    { value: "French", viewValue: "fr-FR" },

  ];
  const [languageList, setLanguageList] = useState([])

  const [contentData, setContentData] = useState([ { value: "loginnow", viewValue: "Login Now" },
  { value: "sendotp", viewValue: "Send OTP" },
  { value: "forgotPwd", viewValue: "Forgot Password" },
  { value: "selectsmcno", viewValue: "Select Your SMC Number" },]);

  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const storedData = localStorage.getItem("userDetail");

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      setUserData(parsedData);
    }
  }, []);
  const getContentList = async (language) => {
    try {
      setLoading(true)
      const res = await ApiService.getAll(
        "sdmcontentpages/getjsoncontent?pageKey="+dataSource.PageKey,language
      );
      if (res) {
        setcontentList(res);
        setLoading(false)
        setContentResponse(res)
        // setContentData(res)
      }
    } catch (error) {
      setLoading(false)
      console.error("Error updating toggle state:", error);
    }
  };
  const getLanguageList = async (language) => {
    // try {
    //   const res = await ApiService.post("sdmlanguage/list", filterData);
    //   if (res) {
    //     // setcontentList(res);
    //     // setContentData(res)
    //     setLanguageList(res.SdMLanguage)
    //   }
    // } catch (error) {
    //   console.error("Error updating toggle state:", error);
    // }
    let langData = [
      {
        LanguageName: "English",
        LanguageShortName: "en"
      },
      {
        LanguageName: "Tamil",
        LanguageShortName: "ta"
      },
      {
        LanguageName: "Telugu",
        LanguageShortName: "te"
      },
      {
        LanguageName: "Kannada",
        LanguageShortName: "kn"
      },
      {
        LanguageName: "Malayalam",
        LanguageShortName: "ml"
      },
      {
        LanguageName: "Hindi",
        LanguageShortName: "hi"
      },
      {
        LanguageName: "Bengali",
        LanguageShortName: "bn"
      },
      {
        LanguageName: "Marathi",
        LanguageShortName: "mr"
      },
      {
        LanguageName: "Odia",
        LanguageShortName: "or"
      },
    ];
    setLanguageList(langData)
  };
  // const updateValue = async (data) => {
    
  //   try {
  //     const res = await ApiService.put("sdmcontentpages/updatejsoncontent", data,languageValues);
  //     if (res) {
  //       // console.log(res)
  //     }
  //   } catch (error) {
  //     console.error("Error updating toggle state:", error);
  //   }
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const LanguagehandleChange = (e) => {
    const { name, value } = e.target;
   setlanguageValues(value)
   getContentList(value);

  };
  function getCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(
      3,
      "0"
    );

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission
    const updatedFormData = {
      ...formData,
      CreatedOn: getCurrentDateTime(),
      CreatedBy: userData.Id,
      IsActive: formData.IsActive == "Active" ? true : false,
    };
    if (imageFile) {
      updatedFormData.ImageUrl = imageFile.name;
    }

    setFormData(updatedFormData);
    try {
      const res = await ApiService.post("sdmcontentpages", updatedFormData);
      if (res) {
        const uploadedImages = {
          Id: res.PrimaryKey,
          FilePath: imageFile,
        };
        try {
          const imgApi = await ApiService.put(
            "sdmcontentpages/uploadimage",
            uploadedImages
          );
          // if(imgApi){
          handleCancel();
          // }
        } catch (error) {
          console.error("Error updating toggle state:", error);
        }
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
    // console.log("Form submitted:", updatedFormData);
  };

  const handleCancel = () => {
    // Handle cancel action
    onCancel();
  };
  const handleChange1 = (content) => {
    setFormData((prevData) => ({
      ...prevData,
      Content: content,
    }));
  };
  const handleChange2 = (content) => {
    setFormData((prevData) => ({
      ...prevData,
      Notes: content,
    }));
  };
  const handleimageFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Validate and set the icon file
      if (selectedFile.size > 2 * 1024 * 1024) {
        setimageError("Icon size should not exceed 2MB.");
        setimageFile(null);
      } else if (
        !["image/jpeg", "image/png", "image/bmp", "image/gif"].includes(
          selectedFile.type
        )
      ) {
        setimageError(
          "Only JPG, JPEG, PNG, BMP, and GIF formats are supported."
        );
        setimageFile(null);
      } else {
        setimageFile(selectedFile);
        setimageError(null);
      }
    }
  };
  const clearimageFile = () => {
    setimageFile(null);
    setimageError(null);
  };
  const openBanner = () => {
    setBannerDialogOpen(true);
  };
  const BannerClose = () => {
    setBannerDialogOpen(false);
  };
  const openEdit = () => {
    setEditDialogOpen(true);
  };
  const EditClose = () => {
    setEditDialogOpen(false);
    getContentList(languageValues)
  };
  const handleDeleteClick = () => {};
  const handleKeyUp = async (event) => {
    const searchQuery = event.target.value;
    setSearch(searchQuery);
    // console.log(contentList,searchQuery);
    const filterCondition = value => value.toLowerCase().includes(searchQuery);

// Filter the key-value pairs based on the condition
const filteredTranslations = Object.entries(contentResponse)
    .filter(([key, value]) => filterCondition(value))
    .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
    }, {});
    setcontentList(filteredTranslations)
    // console.log("Filtered Translations:", filteredTranslations);

  };
  // bannersubmit
  const handleFormSubmit = (formData) => {
    setBannerFormDataList((prevList) => [...prevList, formData]);
    // console.log(formData); // Example: Logging formData
    setBannerDialogOpen(false); // Close the dialog after form submission
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDragStart = (event, item) => {
    const oldNo = item.Priority;
    setOldPriority(oldNo);
    // console.log(item);
    setDraggedItem(item);
    event.dataTransfer.setData("text/plain", ""); // Required for Firefox
  };
  const handleDrop = (event, droppedItem) => {
    event.preventDefault();
    setDroppedItem(droppedItem);
    // handleDialogOpen()
    // // console.log(oldNo)
  };
  const handleEditClick = (item) => {
    // console.log(item);
    setBannerEditData(item);
    setBannerDialogOpen(true);
  };
  const handleContentEditClick = (item,key,value) => {
    // console.log(item);
    setContentEditData(item);
    setEditKey(key)
    setEditValue(value)
    // console.log(key);
    setEditDialogOpen(true);
  };
  const renderSortIcon = (column) => {
    if (sortBy === column) {
      return sortOrder === 'asc' ? <ArrowUpward className='sortIcon'/> : <ArrowDownward className='sortIcon' />;
    }
    return null;
  };
  return (
    <div>
      <AddBannerDialog
        // title={"Confirmation"}
        // content={"Are you sure want to delete this list?"}
        open={BannerDialogOpen}
        onClose={BannerClose}
        onConfirm={handleDeleteClick}
        onFormSubmit={handleFormSubmit} // Pass the callback function
        EditData={BannerEditData}
      />
      <EditContentDialog
        // title={"Confirmation"}
        // content={"Are you sure want to delete this list?"}
        open={EditDialogOpen}
        onClose={EditClose}
        // onConfirm={handleDeleteClick}
        // onFormSubmit={updateValue} // Pass the callback function
        EditData={ContentEditData}
        value={editvalue}
        keyValue={editKey}
        pageKey={dataSource.PageKey}
        languageValues={languageValues}
      />
      <div className="mainContainer">
        <div class="dis-flex headTitle">
          <img
            src={leftArrow}
            alt=""
            onClick={handleCancel}
            className="cursor"
          />
          <p>{dataSource.PageName}</p>
        </div>
        <form onSubmit={handleSubmit} className="m-t-24">
          <div className="Content">
            <div className="displayFlex m-b">
              {/* <div className="example-container">
                <TextField
                  label="Page Title"
                  name="ContentTitle"
                  value={formData.ContentTitle}
                  onChange={handleChange}
                  required
                  maxLength={50}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        title="Supports alphabets, numbers and up to 50 characters."
                        placement="top"
                      >
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div> */}

              {/* <div className="example-container">
                <TextField
                  label="Page Key"
                  name="PageKey"
                  value={formData.PageKey}
                  onChange={handleChange}
                  required
                  maxLength={50}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        title="Supports alphabets, numbers and up to 50 characters."
                        placement="top"
                      >
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div> */}
            </div>
           
            {/* <div className="example-container m-b bannerTableWidth">
              <div className="displayFlexBanner">
                <div className="checkbox-lable m-24">Banner</div>
                <Button mat-button className="addUserBtn" onClick={openBanner}>
                  <div className="button-content">
                    <img src={plusIcon} alt="" />
                    <span>Add Banner</span>
                  </div>
                </Button>
              </div>

              <div className="priorityContainer">
                <div className="priorityHeader">
                  <div className="priorityTitleB">Priority</div>
                  <div className="priorityTitleB">Name</div>
                  <div className="priorityTitleB">Web Image</div>
                  <div className="priorityTitleB">Mobile Image</div>
                  <div className="priorityTitleB">Status</div>
                  <div className="priorityTitleB">Action</div>
                </div>
                <div>
                  <div className="priorityContent">
                    <div onDragOver={handleDragOver} className="w-100">
                      {BannerformDataList.length !== 0 ? (
                        BannerformDataList.map((item, index) => (
                          <div
                            className="prioritylist"
                            key={index}
                            draggable
                            onDragStart={(e) => handleDragStart(e, item)}
                            onDrop={(e) => handleDrop(e, item)}
                          >
                            <div className=" priorityTitle bannerList">
                              {item.Priority}
                            </div>
                            <div className="bannerList">{item.Title}</div>
                            <Tooltip title={item.ImageUrl}>
                              <div className="bannerList">
                                {item.ImageUrl.length > 10
                                  ? `${item.ImageUrl.slice(0, 10)}...`
                                  : item.ImageUrl}
                              </div>
                            </Tooltip>
                            <Tooltip title={item.MobileImageUrl}>
                              <div className="bannerList">
                                {item.MobileImageUrl.length > 10
                                  ? `${item.MobileImageUrl.slice(0, 10)}...`
                                  : item.MobileImageUrl}
                              </div>
                            </Tooltip>
                            <div className="bannerList">
                              <label className="switch">
                                <input
                                  className="switch-input"
                                  type="checkbox"
                                  checked={
                                    item.IsActive == "Inactive" ? false : true
                                  }
                                 
                                />
                                <span
                                  className="switch-label"
                                  data-on="Active"
                                  data-off="Inactive"
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="bannerList">
                              <img
                                src={editIcon}
                                alt=""
                                className="action cursor"
                                onClick={() => handleEditClick(item)}
                              />
                              <img
                                src={deleteIcon}
                                alt=""
                                className="action cursor"
                              />
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="prioritylist w100">
                          <div>No Data</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}


            
            <div className="example-container m-b bannerTableWidth">
            {/* <div className="checkbox-lable m-24 mb10">Content</div> */}
              <div className="displayFlexBanner m-b">
              <FormControl>
                  <InputLabel>Language</InputLabel>
                  <Select
                    name="Language"
                    value={languageValues}
                    onChange={LanguagehandleChange}
                    required
                    className="commonInput"
                  >
                    {languageList.map((status) => (
                      <MenuItem key={status.LanguageShortName} value={status.LanguageShortName}>
                        {status.LanguageName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="SearchBtn">
                  <img src={searchIcon} alt="" />
                  <form>
                    <input
                      type="text"
                      placeholder="Search Content"
                      value={search}
                      onChange={(e) => handleKeyUp(e)}
                      onKeyUp={handleKeyUp}
                    />
                  </form>
                </div>
              </div>
              {/* <div className="mainContainer"> */}

              {/* <div className="priorityContainer">
                <div className="priorityHeader">
                  <div className="priorityTitleB">Key</div>
                  <div className="valueTitle">Values</div>
                  <div className="priorityTitleB">Action</div>
                </div>
                <div>
                  <div className="priorityContent">
                    <div className="w-100">
                      {contentList.map((item, index) => (
                        <div
                          className="prioritylist"
                          key={index}
                          draggable
                          onDragStart={(e) => handleDragStart(e, item)}
                          onDrop={(e) => handleDrop(e, item)}
                        >
                          <div className="priorityTitleC colorText">
                            {item.key}
                          </div>
                          <div className="valueTitle">{item.value}</div>
                          <div className="priorityTitleC">
                          <img src= {editIcon} alt=""  className='action cursor' onClick={() => handleContentEditClick(item)} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div> */}
 <div className='tableContainer mt10 m-b'>
    {/* <TableCompontent data={userData}/> */}
    <Paper className="wholeTable"> {/* Use Paper as the container */}
      <Table>
        <TableHead>
          {/* Table header */}
          <TableRow className="tr">
            <TableCell style={{ width: '30%' }} onClick={() => handleSort('ContentTitle')} className="tableHead">Key
            {/* {renderSortIcon('ContentTitle')} */}
            </TableCell>
            <TableCell style={{ width: '20%' }} className="tableHead">Value</TableCell>
            {/* <TableCell style={{ width: '15%' }} className="tableHead">Status</TableCell> */}
            <TableCell style={{ width: '15%' }} className="tableHead">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {contentList.length === 0 ? (
    <TableRow>
      <TableCell colSpan={6} align="center" style={{ textAlign: "center", verticalAlign: "middle" }}>
        No data available
      </TableCell>
    </TableRow>
  ) : (
     Object.entries(contentList).map(([key, value], index) => (
      <TableRow key={index}>
        {/* <TableCell align="left" style={{ width: '30%' }} className="tableBody">{dataSource.ContentTitle} {dataSource.Status === 'pendingReview' && <span className='colStatus'>
          Pending Review
          </span>}</TableCell> */}
        <TableCell align="left" style={{ width: '20%' }} className="tableBody">{key}</TableCell>
        <TableCell align="left" style={{ width: '20%' }} className="tableBody">{value.slice(0, 100)}</TableCell>
    
      <TableCell align="left" style={{ width: '15%' }}>
      <img src= {editIcon} alt=""  className='action cursor' onClick={() => handleContentEditClick(contentList,key,value)}  />
      {/* <img src= {deleteIcon} alt="" className='action cursor' onClick={() => deleteUser(dataSource.Id) } /> */}
      </TableCell>
      </TableRow>
    ))
  )}
         
        </TableBody>
      </Table>
      {/* <TablePagination
        rowsPerPageOptions={[15,25,50,75,100]}
        component="div"
        count={count}
        rowsPerPage={40}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      /> */}
    </Paper>
    </div>
              
            </div>
            {/* <div className="displayFlex m-b">
              <div className="example-container">
                <FormControl>
                  <InputLabel>Review Status</InputLabel>
                  <Select
                    name="Status"
                    value={formData.Status}
                    onChange={handleChange}
                    required
                    className="commonInput"
                  >
                    {reviewOptions.map((status) => (
                      <MenuItem key={status.value} value={status.value}>
                        {status.viewValue}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="example-container">
                <FormControl>
                  <InputLabel>Status</InputLabel>
                  <Select
                    name="IsActive"
                    value={formData.IsActive}
                    onChange={handleChange}
                    required
                    className="commonInput"
                  >
                    {statusOptions.map((status) => (
                      <MenuItem key={status.value} value={status.value}>
                        {status.viewValue}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div> */}
          </div>
          {/* <div className="example-container m-b">
              <TextField
                label="Meta / PageTitle"
                name="MetaPageTitle"
                value={formData.MetaPageTitle}
                onChange={handleChange}
                required
                maxLength={50}
                className="commonInput multiSelectWidth"
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      title="Supports alphabets, numbers and up to 50 characters."
                      placement="top"
                    >
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="example-container m-b">
              <TextField
                label="Meta Keywords"
                name="MetaKeywords"
                value={formData.MetaKeywords}
                onChange={handleChange}
                required
                maxLength={50}
                className="commonInput multiSelectWidth"
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      title="Supports alphabets, numbers and up to 50 characters."
                      placement="top"
                    >
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="example-container m-b">
              <TextField
                label="Meta Description"
                name="MetaDescription"
                value={formData.MetaDescription}
                onChange={handleChange}
                required
                maxLength={50}
                className="commonInput multiSelectWidth"
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      title="Supports alphabets, numbers and up to 50 characters."
                      placement="top"
                    >
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div> */}
          <div className="submitButton">
            {/* <Button type="submit" className="btn">
              Save
            </Button> */}
            <Button className="cancelBtn" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
      {loading && <Loader />}
    </div>
  );
};
export default ContentPageEdit;
