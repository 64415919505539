import React, { useState, useEffect, useRef } from 'react';
import plusIcon from "./../../../../src/images/LeadingIcon.svg"
import searchIcon from './../../../../src/images/search-lg.svg';
import'./../../../../src/styles/common.css'
import deleteIcon from './../../../../src/images/trash-01.svg';
import editIcon from './../../../../src/images/edit-01.svg'
import ApiService from '../../../Services/ApiService';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, MenuItem,TableSortLabel, TablePagination, FormControl,InputLabel,Select } from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons'; // Import icons from Material-UI
import DynamicPageAdd from './DynamicPageAdd';
import DynamicPageEdit from './DynamicPageEdit';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { DialogBox } from '../../Dialog Box';
import { Loader } from '../../Loader';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import _debounce from 'lodash/debounce';

// import EditContentDialog from "./EditContentDialog";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
const DynamicPageList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [showUserList, setShowUserList] = useState("UserList");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [editData, seteditData] = useState();
  const [deleteData, setDeleteData] = useState();


  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setdeleteDialogOpen] = useState(false);
 

  const [dialogData, setDialogData] = useState(); 
  const [filterData, setFilterData] = useState({
    Index: 0,
    Limit: 15,
    OrderByTable: '',
    OrderByField: '',
    Sort: '',
    Aggregator: 'OR',
    Conditions: [
      {
        TableName: 'SdMDynamicPage',
        FieldName: 'PageTitle',
        Operator: 'like',
        FieldValue: ''
      }
    ]
  });
  const [dataSource, setDataSource] = useState([]);
  const [mobileJsonData, setMobileJsonData] = useState([]);
  const [dataSourceRes, setDataSourceRes] = useState([]);

  const [totalRecords, setTotalRecords] = useState(0);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [value, setValue] = useState(0);
  const [editvalue, setEditValue] = useState(null);
  const [EditDialogOpen, setEditDialogOpen] = useState(false);
  const [ContentEditData, setContentEditData] = useState(null);
  const [editKey, setEditKey] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const StyledTab = styled(Tab)({
    "&.Mui-selected": {
      color: "#EE3823",
    },
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // console.log("ddd", event, newPage, page);
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      Index: newPage * rowsPerPage,
    }));
    
  };
  const [userData, setUserData] = useState(null);
  const [mobileJsonRes, setMobileJsonRes] = useState(null);

  useEffect(() => {
    
    const storedData = localStorage.getItem('userDetail');

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      setUserData(parsedData);
    }
    
  }, []);
  useEffect(() => {
  
    if(showUserList == "UserList"){
      fetchData();
    }
    // setDeleteData(false)
  }, [filterData,showUserList]);
// }, [filterData.Index,showUserList,deleteData]);
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset page to 0 when rows per page changes
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      Index: 0,
      Limit: newRowsPerPage, // Update Limit based on the new rows per page
    }));
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogData(false)

    setDialogOpen(false);
  };
const DeleteClose=()=>{
  setdeleteDialogOpen(false)
}

  const handleDialogConfirm = () => {
    setDialogData(true)
    updateToggleState()
    handleDialogClose(); 
  };
  const [userDates, setUserDates] = useState({
    Id: null,
    // UserId: null,
    IsActive: false
  });
  const [deleteId, setdeleteId]=useState()
  const onSlideToggleChange = async (data, event) => {
    const checkeds = event.target.checked;
    try {
      setUserDates({
        ...data,
        // UserId: userData.Id,
        IsActive: checkeds
      });
        handleDialogOpen(userDates);
    } catch (error) {
      console.error('Error updating toggle state:', error);
    }
  };
  const deleteUser = async (id) => {
    try {
      setdeleteDialogOpen(true);
      setdeleteId(id)
    } catch (error) {
      console.error('Error updating toggle state:', error);
    }
  };
  
  const updateToggleState = async () => {
    try {
      const res = await ApiService.isActiveput("Sdmdynamicpage", userDates);
      if (res) {
        const updatedDataSource = dataSource.map(data => {
          if (data.Id === userDates.Id) {
            return { ...data, IsActive: userDates.IsActive };
          }
          return data;
        });
        setDataSource(updatedDataSource);
    enqueueSnackbar('Status Updated Successfully!',{ variant: 'success', autoHideDuration: 3000 });
      }
    } catch (error) {
      console.error('Error updating toggle state:', error);
    }
  };

  const fetchData = async () => {
    setLoading(true); // Set loading state to true before fetching data
    try {
      const response = await ApiService.post("sdmdynamicpage/list",filterData);
      if (response && response.SdMDynamicpage) {
        setDataSource(response.SdMDynamicpage);
        setDataSourceRes(response.SdMDynamicpage)
        setTotalRecords(response.count);
        setCount(response.count);
      }
      setData(response.SdMDynamicpage);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Set loading state to false after fetching data
    }
  };
  const handleCancel = () => {
    setShowUserList("UserList");
  };
  const handleKeyUp = useRef(_debounce(async (event) => {
    // console.log(value);
    const searchQuery = event.target.value.toLowerCase();
    setSearch(searchQuery);
   
    if(value == 1){
      const updatedConditions = Object.fromEntries(
        Object.entries(mobileJsonRes).filter(([key, value]) => value.toLowerCase().includes(searchQuery))
    );
    setMobileJsonData(updatedConditions)
    }
    if(value == 0){
    const updatedConditions = dataSourceRes.filter(page => page.PageTitle.toLowerCase().includes(searchQuery));
    setDataSource(updatedConditions)
    }
   

    // Update filterData state with the updated conditions
    // setFilterData({ ...filterData, Conditions: updatedConditions });
    // // console.log(filterData,updatedConditions,filterData.Conditions);
    // await fetchData()
   
  },600)).current;
  useEffect(() => {
  
    // fetchData(); // Call fetchData initially, and every time filterData changes
    
  }, [filterData.Conditions,filterData]); 
  const handleSort = (column) => {
    // console.log('cccc',column);
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      setFilterData(prevFilterData => ({
        ...prevFilterData,
        OrderByField: column,
        Sort: sortOrder,
        OrderByTable: 'SdMDynamicPage'
      }));
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
   
  };
  const renderSortIcon = (column) => {
    if (sortBy === column) {
      return sortOrder === 'asc' ? <ArrowUpward className='sortIcon'/> : <ArrowDownward className='sortIcon' />;
    }
    return null;
  };
  function handleEditClick(data){
    setShowUserList("UserEdit")
    seteditData(data)
  }
  const handleDeleteClick =async () =>{
    try {
      const res = await ApiService.delete("Sdmdynamicpage",deleteId );
      if (res) {
        setDeleteData(true)
        setdeleteDialogOpen(false)
    enqueueSnackbar('Deleted Successfully!',{ variant: 'success', autoHideDuration: 3000 });
      }
    } catch (error) {
      console.error('Error updating toggle state:', error);
    }
  }
  return (
    <div>
     <DialogBox
        title={"Confirmation"}
        content={"Are you sure want to active/inactive this list?"}
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
      <DialogBox
        title={"Confirmation"}
        content={"Are you sure want to delete this list?"}
        open={deleteDialogOpen}
        onClose={DeleteClose}
        onConfirm={handleDeleteClick}
      />
      {loading && <Loader />}
    {showUserList === 'UserList' ? (
      <div>
      <div className="addUser">
      <p className="indicatorText">Dynamic Pages</p>
        <div className="SearchBtn">
            <img src={searchIcon} alt="" />
        <form >
            <input type="text" placeholder="Search Dynamic Pages List" value={search} onChange={e => setSearch(e.target.value)}
             onKeyUp={handleKeyUp}/>
        </form>
          </div>
          <Button mat-button className="addUserBtn"  onClick={() => setShowUserList("UserAdd")}>
            <div className="button-content">
              <img src= {plusIcon} alt="" />
              <span>Add Page</span>
            </div>
          </Button>
    </div>
    <Box sx={{ width: "100%" }} className="">
          
          <div className='tableContainer'>
    <Paper className="wholeTable"> {/* Use Paper as the container */}
      <Table>
        <TableHead>
          {/* Table header */}
          <TableRow className="tr">
            <TableCell style={{ width: '30%' }} onClick={() => handleSort('PageTitle')} className="tableHead">Page Name
            {renderSortIcon('PageTitle')}</TableCell>
            {/* <TableCell style={{ width: '30%' }} onClick={() => handleSort('PageTitle')} className="tableHead">Page Key
            {renderSortIcon('PageTitle')}</TableCell> */}
            <TableCell style={{ width: '20%' }} onClick={() => handleSort('PageKey')} className="tableHead">URL
            {renderSortIcon('PageKey')}
            </TableCell>
            {/* <TableCell style={{ width: '20%' }} className="tableHead">Template</TableCell> */}
            <TableCell style={{ width: '15%' }} className="tableHead">Status</TableCell>
            <TableCell style={{ width: '15%' }} className="tableHead">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {dataSource.length === 0 ? (
    <TableRow>
      <TableCell colSpan={6} align="center" style={{ textAlign: "center", verticalAlign: "middle" }}>
        No data available
      </TableCell>
    </TableRow>
  ) : (
    dataSource.map((dataSource, index) => (
      <TableRow key={index}>
        <TableCell align="left" style={{ width: '30%' }} className="tableBody">{dataSource.PageTitle} {dataSource.Status === 'pendingReview' && <span className='colStatus'>
          Pending Review
          </span>}</TableCell>
          <TableCell align="left" style={{ width: '20%' }} className="tableBody">{dataSource.PageKey }</TableCell>
        {/* <TableCell align="left" style={{ width: '20%' }} className="tableBody">{dataSource.ImageUrl ? dataSource.ImageUrl : "-"}</TableCell>
        <TableCell align="left" style={{ width: '20%' }} className="tableBody">{dataSource.TemplateName}</TableCell> */}
      <TableCell align="left" style={{ width: '15%' }}>
      <label className="switch">
<input
  className="switch-input"
  type="checkbox"
  checked={dataSource.IsActive}
  onChange={(event) => onSlideToggleChange(dataSource, event)}
/>
<span className="switch-label" data-on="Active" data-off="Inactive"></span>
<span className="switch-handle"></span>
</label>
      </TableCell>
      <TableCell align="left" style={{ width: '15%' }}>
      <img src= {editIcon} alt=""  className='action cursor' onClick={() => handleEditClick(dataSource) } />
      {/* <img src= {deleteIcon} alt="" className='action cursor' onClick={() => deleteUser(dataSource.Id) } /> */}
      </TableCell>
      </TableRow>
    ))
  )}
         
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15,25,50,75,100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      />
    </Paper>
    </div>
       
        </Box>

    
    </div>
    ) : showUserList === "UserAdd" ? (<DynamicPageAdd onCancel={handleCancel}  />) :(<DynamicPageEdit onCancel={handleCancel} dataSource={editData} />)}    </div>
  );
};

export default DynamicPageList;
