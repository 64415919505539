import React, { useState, useEffect } from "react";
import leftArrow from "./../../../../src/images/left-arrow.svg";
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import only the necessary Quill styles
import Card from '@material-ui/core/Card';
import FormHelperText from "@mui/material/FormHelperText";
import CloseIcon from '@material-ui/icons/Close';
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import ApiService from "../../../Services/ApiService";
// import Chip from "@mui/material/Chip";
// import Autocomplete from "@mui/material/Autocomplete";
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import Chip from '@material-ui/core/Chip';
// import { TextField as MuiTextField } from '@mui/material/TextField';
import { TextField as MaterialUITextField } from "@material-ui/core";
import { TextField as MuiTextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Loader } from '../../Loader';

const ProductAdd = ({ onCancel }) => {
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean']
  ];

  const [formData, setFormData] = useState({
    ProductName: "",
    Cost: 0,
    PromotionalCost: 0,
    IsNewUser:false,
    IsExistingUser:false,
    SavingCost:0,
    ImageUrl:"",
    PromotionalSpace1:'',
    PromotionalSpace2:'',
    IsActive: "Active",
  });
  const [PackageArray, setPackage] = useState([]);
  const [value, setValue] = React.useState([]);
  const [Packagevalue, setPackageValue] = React.useState([]);
  const [networkvalue, setnetworkValue] = React.useState([]);
  const [selectedChannels, setSelectedChannels] = useState({});
  const [selectedGenre, setSelectedGenre] = useState([]);
  const [checkboxVali, setcheckboxVali] = useState(false);


  useEffect(() => {
    getPackageList();
    const storedData = localStorage.getItem("userDetail");

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      setUserData(parsedData);
    }
  }, []);

 

  const getPackageList = async () => {
    const typeRequest = {
      Index: 0,
      Limit: 10000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "",
      Conditions: [],
    };
    setLoading(true)
    try {
      const res = await ApiService.post("sdmpackage/list/v1", typeRequest);
      if (res) {
        setPackage(res);
    setLoading(false)
      }
    } catch (error) {
    setLoading(false)

      console.error("Error updating toggle state:", error);
    }
  };
  // useEffect(() => {}, [userData]);

 
  const [loading, setLoading] = useState(true);

  const statusOptions = [
    { value: "Active", viewValue: "Active" },
    { value: "Inactive", viewValue: "Inactive" },
  ];
  const [userData, setUserData] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "IsExistingUser" || name == "IsNewUser") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: e.target.checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    // console.log(e.target.checked);
  };
  const handleChange1=(content)=>{
    // const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      "PromotionalSpace1": content,
    }));
  }
  const handleChange2=(content)=>{
    // const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      "PromotionalSpace2": content,
    }));
  }
  function getCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.IsNewUser && !formData.IsExistingUser) {
      setcheckboxVali(true);
      return; // Prevent form submission
    } 
    const transformedArray = Packagevalue.map(item => ({
    
      ProductPackageId: 0,
      PackageId: item.Id,
      PackageName: item.PackageName
    }));
      const updatedFormData = {
        ...formData,
        CreatedOn: getCurrentDateTime(),
        CreatedBy: userData.Id,
        IsActive: formData.IsActive == "Active" ? true : false,
        ProductStatus: "pendingReview",
        productWithPackages:transformedArray,
        // ChannelNumber:0
      };
      setFormData(updatedFormData);
       try {
        const res = await ApiService.post("sdmproduct", updatedFormData);
        if (res) {
          // const uploadedImages = {
          //   'Id': res.PrimaryKey,
          //   'FilePath':file
          // }
          const formData = new FormData();
          formData.append('FilePath', file?.name);
          formData.append('File', file); 
          formData.append('Id', res.PrimaryKey);
          try{
            if(file){
              const imgApi = await ApiService.put('sdmproduct/uploadimage',formData) 
            }
          // if(imgApi){
          // handleCancel();
          // }
          if (res.Error == false) {
            handleCancel();
            enqueueSnackbar('Added Successfully!',{ variant: 'success', autoHideDuration: 3000 });
          } else {
              enqueueSnackbar(res.Message,{ variant: 'warning', autoHideDuration: 3000 });   
          }
          } catch (error){
        console.error("Error updating toggle state:", error);
          }
        }
      } catch (error) {
        console.error("Error updating toggle state:", error);
      }
  };

  const handleCancel = () => {
    // Handle cancel action
    onCancel();
  };
  const handleKeyDown = (e) => {
    const keynum = e.which || e.keyCode;
  
    // Allow backspace, tab, and enter keys
    if (keynum === 8 || keynum === 9 || keynum === 13) {
      return;
    }
  
    // Allow only numeric characters and dot
    if (
      (keynum < 48 || keynum > 57) && // numeric characters
      (keynum !== 46 && keynum !== 190) // dot characters (190 for keypad dot)
    ) {
      e.preventDefault();
    }
  };
  useEffect(() => {}, [value, Packagevalue,networkvalue]);
  // image fields
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 2 * 1024 * 1024) {
        setError('Image size should not exceed 2MB.');
        setFile(null); // Clear the file state
      } else if (!['image/jpeg', 'image/png', 'image/bmp', 'image/gif'].includes(selectedFile.type)) {
        setError('Only JPG, JPEG, PNG, BMP, and GIF formats are supported.');
        setFile(null); // Clear the file state
      } else {
        setFile(selectedFile);
        setFormData((prevData) => ({
          ...prevData,
          ImageUrl: selectedFile.name,
        }));
    // // console.log(formData.LogoUrl)
        setError(null); // Clear any existing error
        // onFileSelected(selectedFile);
      }
    }
  };
  useEffect(() => {
  }, [formData.ImageUrl]);
  const clearFile = () => {
    setFile(null);
    setError(null);
  };
  
  return (
    <div>
            {loading && <Loader />}
            <div className="mainContainer">
      <div class="dis-flex headTitle">
        <img src={leftArrow} alt="" onClick={handleCancel} className="cursor" />
        <p>Create Product</p>
      </div>
      <form onSubmit={handleSubmit} className="m-t-24">
        <div className="Content">
          <div className="displayFlex m-b">
            <div className="example-container">
              <MaterialUITextField
                label="Name"
                name="ProductName"
                value={formData.ProductName}
                onChange={handleChange}
                required
                inputProps={{ maxLength: 50 }}
                className="commonInput"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="example-container">
              <MaterialUITextField
                label="Cost"
                name="Cost"
                value={formData.Cost}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                required
                inputProps={{ maxLength: 4 }}                
                className="commonInput"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            {/* <div className="example-container">
              <MaterialUITextField
                label="Promotional Cost"
                name="PromotionalCost"
                value={formData.PromotionalCost}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                inputProps={{ maxLength: 4 }}
                className="commonInput"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports numbers upto 50 chars.">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div> */}
          </div>
          <div className="displayFlex">
            <div className="example-container">
              <MaterialUITextField
                label="Saving Cost"
                name="SavingCost"
                value={formData.SavingCost}
                onChange={handleChange}
                inputProps={{ maxLength: 50 }}
                className="commonInput"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
           
            <div className="exmple-container p-21">
            <div className="commonInput">
              <p className="post">User Type</p>
              <Checkbox
                checked={formData.IsNewUser}
                name="IsNewUser"
                onChange={handleChange}
                sx={{
                  color: "#EE3823",
                  "&.Mui-checked": {
                    color: "#EE3823",
                  },
                }}
              />{" "}
              New
              <Checkbox
                checked={formData.IsExistingUser}
                name="IsExistingUser"
                onChange={handleChange}
                sx={{
                  color: "#EE3823",
                  "&.Mui-checked": {
                    color: "#EE3823",
                  },
                }}
              />{" "}
              Existing
              {/* Validation error message */}
              {checkboxVali &&
                !formData.IsExistingUser &&
                !formData.IsNewUser && (
                  <FormHelperText error>
                    Please select at least one option.
                  </FormHelperText>
                )}
            </div>
          </div>

          <div className="example-container p-b-29">
      <div className="checkbox-lable m-24">
        Logo
        <span className="cursor imgInfoIcon">
        <Tooltip title="Supported form-.jpg, .png. Max file supported-2MB">
              <InfoIcon className="Icon-style svg-info " />
        </Tooltip>
        </span>
      </div>
      <div className="disflex">
        <div>
          <label htmlFor="fileInput" className="upload-file-label">Choose Files</label>
          <input 
            className="upload-file" 
            type="file" 
            id="fileInput" 
            onChange={handleFileChange} 
          />
        </div>
        <span>
          {file && <a onclick="event.preventDefault();" className="uploaded-file-link">{file?.name}</a>}
          {/* {file && <button onClick={clearFile} >&times;</button>} */}
          {file && 
              <CloseIcon className="closeUpload cursor" onClick={clearFile} />
            }
        </span>     
      </div>
      {error && <div className="imgError">{error}</div>}
      <p className="imgInfo">[Recommended for this image pixel width(200) x height(150)]</p>
    </div>
          </div>
         
          {PackageArray.SdMPackage &&
            PackageArray.SdMPackage.length > 0 && (
              <div className="exmple-container p-45">
                <div className="commonInput">
                  <Autocomplete
                    multiple
                    id="fixed-tags-demo3"
                    value={Packagevalue}
                    onChange={(event, newValue) => {
                      setPackageValue(newValue);
                    }}
                    options={PackageArray.SdMPackage.filter(
                      (option) =>
                        !value.some((selected) => selected.Id === option.Id)
                    )}
                    getOptionLabel={(option) => option.PackageName}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option.PackageName}
                          {...getTagProps({ index })}
                          className="chipsStyle"
                          // disabled={ListArray.indexOf(option) !== -1}
                        />
                      ))
                    }
                    style={{ width: 858, marginBottom: 24 }}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        label="Package"
                        placeholder="Package"
                      variant='standard'
                      />
                    )}
                  />
                </div>
              </div>
            )}
            <div className="example-container m-b">
            <p className="post mb10">Promotional Space1</p>
          <ReactQuill   theme="snow"  className="quill-editor multiSelectWidth"  value={formData.PromotionalSpace1} onChange={handleChange1} modules={{
          toolbar: toolbarOptions, // Pass the custom toolbar options
        }} placeholder={"Write something"} />
            </div>
            <div className="example-container m-b">
            <p className="post mb10">Promotional Space2</p>
          <ReactQuill   theme="snow"  className="quill-editor multiSelectWidth" value={formData.PromotionalSpace2} onChange={handleChange2} modules={{
          toolbar: toolbarOptions, // Pass the custom toolbar options
        }} placeholder={"Write something"} />
            </div>
          <div className="displayFlex m-b">
          <div className="example-container">
            <FormControl>
              <InputLabel>Status</InputLabel>
              <Select
                name="IsActive"
                value={formData.IsActive}
                onChange={handleChange}
                required
                className="commonInput"
              >
                {statusOptions.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.viewValue}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          </div>
     

        </div>
        <div className="submitButton">
          <Button type="submit" className="btn">
            Save
          </Button>
          <Button className="cancelBtn" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
    </div>
   
  );
};

export default ProductAdd;
