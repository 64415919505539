import React, { useState, useEffect,useRef } from 'react';
import plusIcon from "./../../../../src/images/LeadingIcon.svg"
import searchIcon from './../../../../src/images/search-lg.svg';
import'./../../../../src/styles/common.css'
import deleteIcon from './../../../../src/images/trash-01.svg';
import editIcon from './../../../../src/images/edit-01.svg'
import ApiService from '../../../Services/ApiService';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, TableSortLabel, TablePagination } from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons'; // Import icons from Material-UI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { DialogBox } from '../../Dialog Box';
import { Loader } from '../../Loader';
import * as XLSX from 'xlsx'; // Import xlsx library
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { Details } from '../../Details';
import _debounce from 'lodash/debounce';
import moment from 'moment';
const RegisterCallbackList = () => {
  const [showUserList, setShowUserList] = useState("UserList");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [editData, seteditData] = useState();
  const [detailData, setDetailData] = useState();
  const [deleteData, setDeleteData] = useState();


  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setdeleteDialogOpen] = useState(false);

  const [dialogData, setDialogData] = useState(); 
  const [filterData, setFilterData] = useState({
    Index: 0,
    Limit: 15,
    OrderByTable: 'SdMRegisterCallback',
    OrderByField: 'id',
    Sort: 'desc',
    Aggregator: 'OR',
    Conditions: [
      {
        TableName: 'SdMRegisterCallback',
        FieldName: 'FName',
        Operator: 'like',
        FieldValue: ''
      },
      {
        TableName: 'SdMRegisterCallback',
        FieldName: 'LName',
        Operator: 'like',
        FieldValue: ''
      },
      {
        TableName: 'SdMRegisterCallback',
        FieldName: 'MobileNo',
        Operator: 'like',
        FieldValue: ''
      },
      {
        TableName: 'SdMRegisterCallback',
        FieldName: 'Email',
        Operator: 'like',
        FieldValue: ''
      },
      
    ]
  });
  const [dataSource, setDataSource] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // console.log("ddd", event, newPage, page);
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      Index: newPage * rowsPerPage,
    }));
    
  };
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const storedData = localStorage.getItem('userDetail');

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      setUserData(parsedData);
    }
  }, []);
  useEffect(() => {
  
    if(showUserList == "UserList"){
      fetchData();
    }
    // setDeleteData(false)
  }, [filterData,showUserList]);
// }, [filterData.Index,showUserList,deleteData]);
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset page to 0 when rows per page changes
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      Index: 0,
      Limit: newRowsPerPage, // Update Limit based on the new rows per page
    }));
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogData(false)

    setDialogOpen(false);
  };
const DeleteClose=()=>{
  setdeleteDialogOpen(false)
}
  const handleDialogConfirm = () => {
    setDialogData(true)
    updateToggleState()
    handleDialogClose(); 
  };
  const [userDates, setUserDates] = useState({
    Id: null,
    UserId: null,
    IsActive: false
  });
  const [deleteId, setdeleteId]=useState()
  const onSlideToggleChange = async (id, event) => {
    const checkeds = event.target.checked;
    try {
      setUserDates({
        ...userDates,
        Id: id,
        UserId: userData.Id,
        IsActive: checkeds
      });
        handleDialogOpen(userDates);
    } catch (error) {
      console.error('Error updating toggle state:', error);
    }
  };
  const deleteUser = async (id) => {
    try {
      setdeleteDialogOpen(true);
      setdeleteId(id)
    } catch (error) {
      console.error('Error updating toggle state:', error);
    }
  };
  
  const updateToggleState = async () => {
    try {
      const res = await ApiService.isActiveput("sdmfaqs/isactive", userDates);
      if (res) {
        const updatedDataSource = dataSource.map(data => {
          if (data.Id === userDates.Id) {
            return { ...data, IsActive: userDates.IsActive };
          }
          return data;
        });
        setDataSource(updatedDataSource);
      }
    } catch (error) {
      console.error('Error updating toggle state:', error);
    }
  };
  useEffect(() => {
   
  }, []);

  const fetchData = async () => {
    setLoading(true); // Set loading state to true before fetching data
    try {
      const response = await ApiService.post("sdmregistercallback/list", filterData);
      if (response && response.SdMRegisterCallback) {
        setDataSource(response.SdMRegisterCallback);
        setTotalRecords(response.count);
        setCount(response.count);
      }
      setData(response.data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Set loading state to false after fetching data
    }
  };
  const handleCancel = () => {
    setShowUserList("UserList");
  };
  const handleKeyUp = useRef(_debounce(async (event) => {
    const searchQuery = event.target.value;
    setSearch(searchQuery);
    const updatedConditions = filterData.Conditions.map(condition => ({
      ...condition,
      FieldValue: searchQuery
    }));
  
    // Update filterData state with the updated conditions
    setFilterData({ ...filterData, Conditions: updatedConditions });
    // console.log(filterData,updatedConditions,filterData.Conditions);
    // await fetchData()
   
  },600)).current;
  useEffect(() => {
  
    // fetchData(); // Call fetchData initially, and every time filterData changes
    
  }, [filterData.Conditions,filterData]); 
  const handleSort = (column) => {
    // console.log('cccc',column);
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      setFilterData(prevFilterData => ({
        ...prevFilterData,
        OrderByField: column,
        Sort: sortOrder,
        OrderByTable: 'SdMRegisterCallback'
      }));
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
   
  };
  const renderSortIcon = (column) => {
    if (sortBy === column) {
      return sortOrder === 'asc' ? <ArrowUpward className='sortIcon'/> : <ArrowDownward className='sortIcon' />;
    }
    return null;
  };
  function handleEditClick(data){
    setShowUserList("UserEdit")
    seteditData(data)
  }
  function handleDetailClick(data){
    let excludedKeys = ['Id', 'CreatedByName', 'ModifiedByName'];
    const getData =[
      {
        name:'Name',
        value:data.FName+' '+data.LName
      },
      {
        name:'Email',
        value:data.Email
      },
      {
        name:'Mobile No',
        value:data.MobileNo
      },
      {
        name:'Model Name',
        value:data.ModelName
      },
      {
        name:'Pack Name',
        value:data.PackName
      },
      {
        name:'Capacity Fee',
        value:data.CapacityFee
      },
      {
        name:'Preferd Date',
        value:(data.PrefDate && (data.PrefDate)?.trim())?formatDate(data.PrefDate):""
      },
      {
        name:'Address',
        value:data.Address
      },
      {
        name:'Area',
        value:data.Area
      },
      {
        name:'City',
        value:data.City
      },
      {
        name:'State',
        value:data.State
      },
      {
        name:'Pincode',
        value:data.Zip
      },
      {
        name:'Created On',
        value:data.CreatedDate?formatDateAndTime(data.CreatedDate):""
      }
    ]
    setDetailData(getData)
    setShowUserList("DetailScreen")
  }
  const handleDeleteClick =async () =>{
    try {
      const res = await ApiService.delete("sdmfaqs",deleteId );
      if (res) {
        setDeleteData(true)
        setdeleteDialogOpen(false)
      }
    } catch (error) {
      console.error('Error updating toggle state:', error);
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Then specify how you want your dates to be formatted
    const dateOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    };

    // Format the date and time separately
    const datePart = date.toLocaleDateString('en-GB', dateOptions); // 'dd/mm/yyyy'

    // Convert datePart to dd-mm-yyyy
    const [day, month, year] = datePart.split('/');
    const formattedDate = `${day}-${month}-${year}`;

    // Combine date and time parts
    return `${formattedDate}`;
  };

  const formatDateAndTime = (dateString) => {
    const utcMoment = moment.utc(dateString);
    const localMoment = utcMoment.local();
    const date = new Date(localMoment);
    // Specify the options for formatting the date
    const dateOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    };

    const timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
    };

    // Format the date and time separately
    const datePart = date.toLocaleDateString('en-GB', dateOptions); // 'dd/mm/yyyy'
    const timePart = date.toLocaleTimeString('en-US', timeOptions); // 'hh:mm:ss AM/PM'

    // Convert datePart to dd-mm-yyyy
    const [day, month, year] = datePart.split('/');
    const formattedDate = `${day}-${month}-${year}`;

    // Combine date and time parts
    return `${formattedDate}, ${timePart}`;
  };

  const handleExportClick = async () => {
    setLoading(true);
    try {
      const blobData = await ApiService.export("sdmregistercallback/export");
      
      // Create a Blob object from the binary data received
      const blob = new Blob([blobData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
      // Create a temporary URL for the Blob object
      const url = window.URL.createObjectURL(blob);
  
      // Create a temporary <a> element to trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Register_Callback_exported_data.xlsx';
      document.body.appendChild(a);
      a.click();
  
      // Clean up by revoking the temporary URL
      window.URL.revokeObjectURL(url);
      setLoading(false);
    } catch (error) {
      console.error('Error exporting data:', error);
      setLoading(false);
    }
  };

  return (
    <div>
     <DialogBox
        title={"Confirmation"}
        content={"Are you sure want to active/inactive this list?"}
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
      <DialogBox
        title={"Confirmation"}
        content={"Are you sure want to delete this list?"}
        open={deleteDialogOpen}
        onClose={DeleteClose}
        onConfirm={handleDeleteClick}
      />
      {loading && <Loader />}
    {showUserList === 'UserList' ? (
      <div>
      <div className="addUser">
      <p className="indicatorText">Register Callback</p>
        <div className="SearchBtn">
            <img src={searchIcon} alt="" />
        <form >
            <input type="text" placeholder="Search Register Callback List" value={search} onChange={e => setSearch(e.target.value)}
             onKeyUp={handleKeyUp}/>
        </form>
          </div>
          <Button mat-button className="addUserBtn"  onClick={() => handleExportClick()}>
            <div className="button-content">
              <img src= {plusIcon} alt="" />
              <span>Export(s)</span>
            </div>
          </Button>
    </div>
    <div className='tableContainer'>
    {/* <TableCompontent data={userData}/> */}
    <Paper className="wholeTable"> {/* Use Paper as the container */}
      <Table>
        <TableHead>
          {/* Table header */}
          <TableRow className="tr">
            <TableCell style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} onClick={() => handleSort('Question')} className="tableHead">First Name
            {renderSortIcon('Question')}</TableCell>
            <TableCell style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} className="tableHead">Last Name</TableCell>
            <TableCell style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} className="tableHead">Mobile</TableCell>
            <TableCell style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} className="tableHead">Email</TableCell>
            <TableCell style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} className="tableHead">State</TableCell>
            <TableCell style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} className="tableHead">City</TableCell>
            <TableCell style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} className="tableHead">Zip</TableCell>
            <TableCell style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} className="tableHead">Capacity Fee</TableCell>
            <TableCell style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} className="tableHead">Prefer Date</TableCell>
            <TableCell style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} className="tableHead">Created On</TableCell>
            <TableCell style={{ width: '70px', whiteSpace: 'nowrap', zIndex: 1, position: 'sticky', right: 0, backgroundColor: 'inherit', paddingLeft: '10px !important' }} className="tableHead">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {dataSource.length === 0 ? (
    <TableRow>
      <TableCell colSpan={6} align="center" style={{ textAlign: "center", verticalAlign: "middle" }}>
        No data available
      </TableCell>
    </TableRow>
  ) : (
    dataSource.map((dataSource, index) => (
      <TableRow key={index}>
        <TableCell align="left" style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} className="tableBody">{dataSource.FName}</TableCell>
        <TableCell align="left" style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} className="tableBody">{dataSource.LName}</TableCell>
        <TableCell align="left" style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} className="tableBody">{dataSource.MobileNo}</TableCell>
        <TableCell align="left" style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} className="tableBody">{dataSource.Email}</TableCell>
        <TableCell align="left" style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} className="tableBody">{dataSource.State}</TableCell>
        <TableCell align="left" style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} className="tableBody">{dataSource.City}</TableCell>
        <TableCell align="left" style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} className="tableBody">{dataSource.Zip}</TableCell>
        <TableCell align="left" style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} className="tableBody">{dataSource.CapacityFee}</TableCell>
        <TableCell align="left" style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} className="tableBody">{(dataSource.PrefDate && dataSource.PrefDate?.trim())?formatDate(dataSource.PrefDate):""}</TableCell>
        <TableCell align="left" style={{ width: 'auto', whiteSpace: 'nowrap', zIndex: 'auto', position: 'static', right: 'auto', backgroundColor: 'inherit' }} className="tableBody">{dataSource.CreatedDate?formatDateAndTime(dataSource.CreatedDate):""}</TableCell>

     
      <TableCell align="left" style={{ width: '70px', whiteSpace: 'nowrap', zIndex: 1, position: 'sticky', right: 0, backgroundColor: '#fff' }}>
      <WysiwygIcon className='action cursor p-t-3' sx={{ color: '#475467', fontSize: 20 }} onClick={() => handleDetailClick(dataSource) } />
      {/* <img src= {editIcon} alt=""  className='action cursor' onClick={() => handleEditClick(dataSource.Id) } /> */}
      {/* <img src= {deleteIcon} alt="" className='action cursor' onClick={() => deleteUser(dataSource.Id) } /> */}
      </TableCell>
      </TableRow>
    ))
  )}
         
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15,25,50,75,100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        style={{
          position: "sticky",
          bottom: 0,
          left: 0,
          backgroundColor: "white",
          zIndex:2
        }}
      />
    </Paper>
    </div>
    </div>
    ) : (<Details onCancel={handleCancel} detailData={detailData}/>)}    </div>
  );
};

export default RegisterCallbackList;