import UserList from './pages/System/Accounts/User/UserList.jsx'
import UserRoleList from './pages/System/Accounts/UserRole/UserRoleList.jsx';
import SystemConfiguration from './pages/System/SystemConfiguration/SystemConfiguraton.jsx';
import ChannelList from './pages/Manage/Channel/ChannelList.jsx'
import NetworkList from './pages/Manage/Network/NetworkList.jsx'
import GenreList from './pages/Manage/Genre/GenreList.jsx'
import PackageList from './pages/Manage/Package/PackageList.jsx'
import ProductList from './pages/Manage/Product/ProductList.jsx'
import PackageTypeList from './pages/Manage/PackageType/PackageTypeList.jsx'
import LanguageList from './pages/Manage/Language/LanguageList.jsx';
import Translate from './pages/Manage/Translate/Translate.jsx';
import BannerList from './pages/CMS/Banner/BannerList.jsx';
import CategoriesList from './pages/CMS/Categories/CategoriesList.jsx';
import ContactUsList from './pages/CMS/Contact Us/ContactUsList.jsx';
import ContentBlocksList from './pages/CMS/Content Blocks/ContentBlocksList.jsx';
import ContentPageList from './pages/CMS/Content Page/ContentPageList.jsx';
import DynamicPageList from './pages/CMS/Dynamic Page/DynamicPageList.jsx';
import CustomerCareSupportList from './pages/CMS/Customer Care Support/CustomerCareSupportList.jsx';
import ErrorOnscreensList from './pages/CMS/Error Onscreens/ErrorOnscreensList.jsx';
import ErrorSymptomsList from './pages/CMS/Error Symptoms/ErrorSymptomsList.jsx';
import ErrorTypeList from './pages/CMS/Error Type/ErrorTypeList.jsx';
import FaqsList from './pages/CMS/Faqs/FaqsList.jsx';
import InstallationDaysList from './pages/CMS/Installation Days/InstallationDaysList.jsx';
import MenuSectionList from './pages/CMS/Menu Section/MenuSectionList.jsx';
import MenusList from './pages/CMS/Menus/MenusList.jsx';
import NewConnectionModelList from './pages/CMS/New Connection Model/NewConnectionModelList.jsx';
import RechargeOffersList from './pages/CMS/Recharge Offers/RechargeOffersList.jsx';
import SunshineCentersList from './pages/CMS/Sunshine Centers/SunshineCentersList.jsx';
import CustomerCareNumberList from './pages/Customer Center/Customer Care Number/CustomerCareNumberList.jsx';
import RegisterCallbackList from './pages/Manage Leads/Register Callback/RegisterCallbackList.jsx';
import BoquetPageList from './pages/Manage/Boquet Page/BoquetPageList.jsx';
import { Signin } from './pages/index.js';

const routes =  {
  items: [
    {
      path: '/dashboard/system',
      name: 'SYSTEM',
      type: 'submenu',
      children: [
        {
          path: '/configuration',
          name: 'CONFIGURATION',
          component: SystemConfiguration
        },
        {
          path: '/account/user',
          name: 'ACCOUNT',
          type: 'supermenu',
      superchildBoolen:true,
          superChild: [
            {
              path: '/account/user',
              name: "USER",
              component: UserList
            },
            // {
            //   path: '/account/userrole',
            //   name: "USER ROLE",
            //   component: UserRoleList
            // }
          ]
        }
      ]
    },
    {
      path: '/dashboard/manage',
      name: 'MANAGE',
      type: 'submenu',
      children: [
        {
          path: '/new-connection-model',
          name: 'BOX TYPE',
          component: NewConnectionModelList
        },
        {
          path: '/network',
          name: 'NETWORK',
          component: NetworkList
        },
        {
          path: '/genre',
          name: 'GENRE',
          component: GenreList
        },
        {
          path: '/language',
          name: 'LANGUAGE',
          component: LanguageList
        },
        {
          path: '/channel',
          name: 'CHANNEL',
          component: ChannelList
        },
        {
          path: '/package',
          name: 'PACKAGE',
          component: PackageList
        },
        {
          path: '/bouquet',
          name: 'BOUQUET',
          component: BoquetPageList
        },
        {
          path: '/package-type',
          name: 'PACKAGE TYPE',
          component: PackageTypeList
        },
        {
          path: '/product',
          name: 'PRODUCT',
          component: ProductList
        },
        {
          path: '/translate',
          name: 'Translate',
          component: Translate
        }
      ]
    },
    {
      path: '/dashboard/cms',
      name: 'CMS',
      type: 'submenu',
      children: [
        // {
        //   path: '/menu-section',
        //   name: 'MENU SECTION',
        //   component: MenuSectionList
        // },
        // {
        //   path: '/menus',
        //   name: 'MENUS',
        //   component: MenusList
        // },
        {
          path: '/banner',
          name: 'BANNER',
          component: BannerList
        },
        {
          path: '/content-pages',
          name: 'CONTENT PAGES',
          component: ContentPageList
        },
        {
          path: '/dynamic-pages',
          name: 'DYNAMIC PAGES',
          component: DynamicPageList
        },
        // {
        //   path: '/content-blocks',
        //   name: 'CONTENT BLOCKS',
        //   component: ContentBlocksList
        // },
        {
          path: '/category',
          name: 'CATEGORIES',
          component: CategoriesList
        },
        {
          path: '/faqs',
          name: 'FAQS',
          component: FaqsList
        },
        {
          path: '/sunshine-centers',
          name: 'SUNSHINE CENTERS',
          component: SunshineCentersList
        },
        {
          path: '/installation-days',
          name: 'INSTALLATION DAYS',
          component: InstallationDaysList
        },
        // {
        //   path: '/errors-onscreens',
        //   name: 'ERRORS ONSCREENS',
        //   component: ErrorOnscreensList
        // },
        // {
        //   path: '/errors-symptoms',
        //   name: 'ERRORS SYMPTOMS',
        //   component: ErrorSymptomsList
        // },
        // {
        //   path: '/errors-types',
        //   name: 'ERRORS TYPES',
        //   component: ErrorTypeList
        // },
        
        // {
        //   path: '/customer-care-support',
        //   name: 'CUSTOMER CARE SUPPORT',
        //   component: CustomerCareSupportList
        // },
        // {
        //   path: '/recharge-offers',
        //   name: 'RECHARGE OFFERS',
        //   component: RechargeOffersList
        // },
        // {
        //   path: '/contact-us',
        //   name: 'CONTACT US',
        //   component: ContactUsList
        // },
      ]
    },
    {
      path: '/dashboard/customer-center',
      name: 'CUSTOMER CENTER',
      type: 'submenu',
      children: [
        {
          path: '/customercare-number',
          name: 'CUSTOMER CARE NUMBER',
          component: CustomerCareNumberList
        }
      ]
    },
    {
      path: '/dashboard/manage-leads',
      name: 'MANAGE LEADS',
      type: 'submenu',
      children: [
        {
          path: '/register-callback',
          name: 'REGISTER CALLBACK',
          component: CustomerCareNumberList
        }
      ]
    },
  ],
  Newroutes : [
    {
      path: '/dashboard/system/configuration',
      name: 'System Configuration',
      component: SystemConfiguration
    },
    {
      path: '/dashboard/system/account/user',
      name: 'USER',
      component: UserList
    },
    // {
    //   path: '/dashboard/system/account/userrole',
    //   name: 'USER ROLE',
    //   component: UserRoleList
    // },
    {
      path: '/dashboard/manage/network',
      name: 'Network',
      component: NetworkList
    },
    {
      path: '/dashboard/manage/genre',
      name: 'Genre',
      component: GenreList
    },
    {
      path: '/dashboard/manage/language',
      name: 'Language',
      component: LanguageList
    },
    {
      path: '/dashboard/manage/channel',
      name: 'Channel',
      component: ChannelList
    },
    {
      path: '/dashboard/manage/package',
      name: 'Package',
      component: PackageList
    },
    {
      path: '/dashboard/manage/bouquet',
      name: 'Bouquet',
      component: BoquetPageList
    },
    {
      path: '/dashboard/manage/package-type',
      name: 'Package Type',
      component: PackageTypeList
    },
    {
      path: '/dashboard/manage/product',
      name: 'Product',
      component: ProductList
    },
    {
      path: '/dashboard/manage/translate',
      name: 'Translate',
      component: Translate
    },
    {
      path: '/dashboard/cms/menu-section',
      name: 'Menu Section',
      component: MenuSectionList
    },
    {
      path: '/dashboard/cms/menus',
      name: 'Menus',
      component: MenusList
    },
    {
      path: '/dashboard/cms/banner',
      name: 'Banner',
      component: BannerList
    },
    {
      path: '/dashboard/cms/content-pages',
      name: 'Content Pages',
      component: ContentPageList
    },
    {
      path: '/dashboard/cms/dynamic-pages',
      name: 'Dynamic Pages',
      component: DynamicPageList
    },
    {
      path: '/dashboard/cms/content-blocks',
      name: 'Content Blocks',
      component: ContentBlocksList
    },
    {
      path: '/dashboard/cms/category',
      name: 'Categories',
      component: CategoriesList
    },
    {
      path: '/dashboard/cms/faqs',
      name: 'FAQS',
      component: FaqsList
    },
    {
      path: '/dashboard/cms/sunshine-centers',
      name: 'Sunshine Center',
      component: SunshineCentersList
    },
    {
      path: '/dashboard/cms/installation-days',
      name: 'Installation Days',
      component: InstallationDaysList
    },
    {
      path: '/dashboard/cms/errors-onscreens',
      name: 'Errors Onscreens',
      component: ErrorOnscreensList
    },
    {
      path: '/dashboard/cms/errors-symptoms',
      name: 'Errors Symptoms',
      component: ErrorSymptomsList
    },
    {
      path: '/dashboard/cms/errors-types',
      name: 'Errors Types',
      component: ErrorTypeList
    },
    {
      path: '/dashboard/manage/new-connection-model',
      name: 'Box Type',
      component: NewConnectionModelList
    },
    {
      path: '/dashboard/cms/customer-care-support',
      name: 'Customer Care Support',
      component: CustomerCareSupportList
    },
    {
      path: '/dashboard/cms/recharge-offers',
      name: 'Recharge Offers',
      component: RechargeOffersList
    }, 
    {
      path: '/dashboard/cms/contact-us',
      name: 'Contact Us',
      component: ContactUsList
    },
    {
      path: '/dashboard/customer-center/customercare-number',
      name: 'Customer Care Number',
      component: CustomerCareNumberList
    },
    {
      path: '/dashboard/manage-leads/register-callback',
      name:'Register Callback',
      component:RegisterCallbackList
    },

    { path: '/signin', component: Signin, name: 'Signin' },
  ]
  
};
export default routes;