import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import leftArrow from './../../../../src/images/left-arrow.svg';
import DialogActions from '@mui/material/DialogActions';
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { FormControl as MuiFormControl } from "@material-ui/core"; // Renamed MuiFormControl
import CloseIcon from '@material-ui/icons/Close';

import {
    TextField,
    Select,
    MenuItem,
    InputLabel,
    IconButton,
  } from "@material-ui/core";
import { useEffect } from 'react';
const AddBannerDialog = ({ open, onClose, onFormSubmit,EditData }) => {
    const PageKey = [
        { value: "PageKey", viewValue: "PageKey1" },
      
      ];
      const PageTitle = [
        { value: "PageTitle", viewValue: "PageTitle1" },
      
      ];
      const reviewOptions= [
        { value: "pendingReview", viewValue: "Pending Review" },
       
      ];
      const statusOptions = [
        { value: "Active", viewValue: "Active" },
        { value: "Inactive", viewValue: "Inactive" },
      ];
      const [imageError, setimageError] = useState(null);
  const [mobileError, setmobileError] = useState(null);
      const [maxWidth, setMaxWidth] = React.useState('lg');
      const [scroll, setScroll] = React.useState('paper');
      const [imageFile, setimageFile] = useState(null); // State for icon file
  const [mobileFile, setmobileFile] = useState(null); // State for banner file
  
      const [formData, setFormData] = useState({
        Title: "",
        Priority:"",
        SourceUrl: "",
        ImageUrl: "",
        MobileImageUrl: "",
        Status: "pendingReview",
        IsActive: "Active",
      });
      
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
     
      const handleKeyDown = (e) => {
        const keynum = e.which || e.keyCode;
      
        // Allow backspace, tab, and enter keys
        if (keynum === 8 || keynum === 9 || keynum === 13) {
          return;
        }
      
        // Allow only numeric characters
        if (keynum < 48 || keynum > 57) {
          e.preventDefault();
        }
      };
    //   images
    const handleimageFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
          // Validate and set the icon file
          if (selectedFile.size > 2 * 1024 * 1024) {
            setimageError("Icon size should not exceed 2MB.");
            setimageFile(null);
          } else if (
            !["image/jpeg", "image/png", "image/bmp", "image/gif"].includes(
              selectedFile.type
            )
          ) {
            setimageError(
              "Only JPG, JPEG, PNG, BMP, and GIF formats are supported."
            );
            setimageFile(null);
          } else {
            setimageFile(selectedFile);
            setFormData((prevData) => ({
                ...prevData,
                "ImageUrl": selectedFile.name,
              }));
            setimageError(null);
          }
        }
      };
    
      const handlemobileFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
          // Validate and set the banner file
          if (selectedFile.size > 2 * 1024 * 1024) {
            setmobileError("Banner size should not exceed 2MB.");
            setmobileFile(null);
          } else if (
            !["image/jpeg", "image/png", "image/bmp", "image/gif"].includes(
              selectedFile.type
            )
          ) {
            setmobileError(
              "Only JPG, JPEG, PNG, BMP, and GIF formats are supported."
            );
            setmobileFile(null);
          } else {
            setmobileFile(selectedFile);
            setFormData((prevData) => ({
                ...prevData,
                "MobileImageUrl": selectedFile.name,
              }));
            setmobileError(null);
          }
        }
      };
      const clearimageFile = () => {
        setimageFile(null);
        setimageError(null);
      };
    
      const clearmobileFile = () => {
        setmobileFile(null);
        setmobileError(null);
      };    
      const handleSubmit = (e) => {
        e.preventDefault();
        // Call the callback function and pass the formData
        onFormSubmit(formData);
        resetForm();

      };
      const resetForm = () => {
        setFormData({
          Title: "",
          Priority:"",
          SourceUrl: "",
          ImageUrl: "",
          MobileImageUrl: "",
          Status: "pendingReview",
          IsActive: "Active",
        });
        setimageError(null);
        setmobileError(null);
        setmobileFile(null);
        setimageFile(null)
      };
  return (
    <Dialog open={open}
    onClose={onClose}
    scroll={scroll}
    fullWidth={true}
    maxWidth={maxWidth}
    sx={{
      width: '60%', // Set width to 600px
      margin: '0 auto', // Center the dialog horizontally
    }}
    >
      <DialogTitle>
      <div class="dis-flex headTitle addbanner">
       <img src={leftArrow} alt="" onClick={onClose} className='cursor'/>   
        <p>Create Banner</p>
    </div>
      </DialogTitle>
      <form onSubmit={handleSubmit} className="m-t-24">
      <DialogContent>
        <div className="Content">
        <div className="displayFlex m-b">
            <div className="example-container">
              <MuiFormControl>
                <InputLabel>Title*</InputLabel>
                <Select
                  name="Title"
                  value={formData.Title}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {PageTitle.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.viewValue}
                    </MenuItem>
                  ))}
                </Select>
              </MuiFormControl>
            </div>
            <div className="example-container">
                <TextField
                  label="Priority"
                  name="Priority"
                  value={formData.Priority}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  required
                  maxLength={50}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              <div className="example-container p-b-29">
              <div className="checkbox-lable m-24">
                Image *
                <span className="cursor imgInfoIcon">
                  <Tooltip title="Supported form-.jpg, .png. Max file supported-2MB">
                    <InfoIcon className="Icon-style svg-info " />
                  </Tooltip>
                </span>
              </div>
              <div className="disflex">
                <div>
                  <label htmlFor="imageFileInput" className="upload-file-label">
                    Choose Files
                  </label>
                  <input
                    className="upload-file"
                    type="file"
                    id="imageFileInput"
                    onChange={handleimageFileChange}
                  />
                </div>
                <span>
                  {imageFile && (
                    <a href="#" className="uploaded-file-link">
                      {imageFile.name}
                    </a>
                  )}
                  {imageFile && (
                    <CloseIcon
                      className="closeUpload cursor"
                      onClick={clearimageFile}
                    />
                  )}
                </span>
              </div>
              {imageError && <div className="imgError">{imageError}</div>}
              <p className="imgInfo">
                [Recommended for this image pixel width(200) x height(150)]
              </p>
            </div>
           
        </div>
        <div className="displayFlex">
            <div className="example-container p-b-29">
              <div className="checkbox-lable m-24">
                Mobile Image *
                <span className="cursor imgInfoIcon">
                  <Tooltip title="Supported form-.jpg, .png. Max file supported-2MB">
                    <InfoIcon className="Icon-style svg-info " />
                  </Tooltip>
                </span>
              </div>
              <div className="disflex">
                <div>
                  <label
                    htmlFor="mobileFileInput"
                    className="upload-file-label"
                  >
                    Choose Files
                  </label>
                  <input
                    className="upload-file"
                    type="file"
                    id="mobileFileInput"
                    onChange={handlemobileFileChange}
                  />
                </div>
                <span>
                  {mobileFile && (
                    <a href="#" className="uploaded-file-link">
                      {mobileFile.name}
                    </a>
                  )}
                  {mobileFile && (
                    <CloseIcon
                      className="closeUpload cursor"
                      onClick={clearmobileFile}
                    />
                  )}
                </span>
              </div>
              {mobileError && <div className="imgError">{mobileError}</div>}
              <p className="imgInfo">
                [Recommended for this image pixel width(200) x height(150)]
              </p>
            </div>
            <div className="example-container">
              <TextField
                label="Source Url"
                name="SourceUrl"
                value={formData.SourceUrl}
                onChange={handleChange}
                required
                maxLength={50}
                className="commonInput"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="example-container">
              <MuiFormControl>
                <InputLabel>Review Status</InputLabel>
                <Select
                  name="Status"
                  value={formData.Status}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {reviewOptions.map((review) => (
                    <MenuItem key={review.value} value={review.value}>
                      {review.viewValue}
                    </MenuItem>
                  ))}
                </Select>
              </MuiFormControl>
            </div>
          
        </div>
        <div className="displayFlex m-b">
            <div className="example-container">
              <MuiFormControl>
                <InputLabel>Status</InputLabel>
                <Select
                  name="IsActive"
                  value={formData.IsActive}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {statusOptions.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.viewValue}
                    </MenuItem>
                  ))}
                </Select>
              </MuiFormControl>
            </div>
          </div>
        </div>
      </DialogContent>
      <div  className="submitButton m-16">
            <Button type="submit" className="btn">
              Save
            </Button>
            <Button className="cancelBtn" onClick={onClose}>
              Cancel
            </Button>
      </div>
      </form>
    </Dialog>
  );
};

export default AddBannerDialog;