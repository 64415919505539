import React from 'react';

const Loader = () => {
  return (

    //    <div class="loader-container" >
    //   <div class="loader"></div>
    // </div>
<div class="loader-container" >
<div class="loader">
  <div class="inner one"></div>
  <div class="inner two"></div>
  <div class="inner three"></div>
</div>
      </div>
    
    
  );
};

export default Loader;