import React, { useState, useEffect } from "react";
import leftArrow from "./../../../src/images/left-arrow.svg";
import { List, ListItem, Divider } from "@mui/material";
import environment from "../../Environment/environment";
import InfoIcon from '@material-ui/icons/Info'; 
const Details = ({ onCancel, detailData }) => {
  const BASE_URL = environment.BASE_URL; // Your base API URL

  const handleCancel = () => {
    // Handle cancel action
    onCancel();
  };

  return (
    <div className="mainContainer">
      <div className="dis-flex headTitle">
        <img src={leftArrow} alt="" onClick={handleCancel} className="cursor" />
        <p>Details</p>
      </div>
      <div>
        <List>
          {detailData.map((list, i) => (
            <React.Fragment key={i}>
              <ListItem>
                <div className="flex-dir">
                  <p className="head-name">{list.name}</p>
                  <div className="image-container">
                    {list.images ? (
                      list.images.map((image, j) => (
                        <img
                          key={j}
                          src={`${BASE_URL}/${image.module}/${image.image}`}
                          alt={image.image} // Provide an alt attribute for accessibility
                          className="Contentdetail-image"
                        />
                      ))
                    ) : (
                      <>
                      <p className="Contentdetail">
                        {/* <span>{list.value}</span> */}
                        {list.value}
                      </p>
                      {list.showTitle && <span title={list.value}><InfoIcon className='Icon-style'/></span>}
                      </>
                    )}
                     {list.html && 
                  <div>
                      <div className="des" dangerouslySetInnerHTML={{ __html: list.description }} />
                  </div>
                  }
                  </div>
                 
                </div>
              </ListItem>
              <Divider className="mat-divider-margin" />
            </React.Fragment>
          ))}
        </List>
      </div>
    </div>
  );
};

export default Details;