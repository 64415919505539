import React, { useState, useEffect, useRef } from 'react';
import searchIcon from './../../../../src/images/search-lg.svg';
import'./../../../../src/styles/common.css';
import editIcon from './../../../../src/images/edit-01.svg'
import ApiService from '../../../Services/ApiService';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, TableSortLabel, TablePagination } from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons'; // Import icons from Material-UI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle,TextField,Select,MenuItem,FormControl,InputLabel } from '@material-ui/core';
import { Loader } from '../../Loader';
import _debounce from 'lodash/debounce';
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { useSnackbar } from 'notistack';
const Translate = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [search, setSearch] = useState("");
    const [count, setCount] = useState(0);
    const [moduleList, setModuleList] = useState([]);
    const [moduleValues,setModuleValues]=useState('')
    const formRef = useRef(null); // Ref to scroll to the form
    const [columnList, setColumnList] = useState([
        {
          Id: 1,
          ColumnName: "No Data",
          DataField: "noData",
          ColumnIcon: "",
          Type: "text",
          IsSorting: true,
        },
          {
            Id: 2,
            ColumnName: "Action",
            DataField: "",
            ColumnIcon: "",
            Type: "action",
            IsSorting: true,
          },
      ]);
    const [dataSource, setDataSource] = useState([]);
    const [formData, setFormData] = useState({});
    const [filterData, setFilterData] = useState({
      Index: 0,
      Limit: 15,
      searchKey: search,
      moduleName: moduleValues
    });
    const [EditDialogOpen, setEditDialogOpen] = useState(false);
    const handleContentEditClick = (item) => {
        setFormData(item);
        setEditDialogOpen(true);
        // Scroll to the form
        if (formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      };
    useEffect( () => {
      getModuleList()
    },[])
    useEffect( () => {
      if(moduleValues && moduleValues!=''){
        fetchData();
      }
    },[filterData])
    useEffect(() => {
        if (EditDialogOpen && formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [EditDialogOpen]);
    const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
        ...formData,
        [name]: value
    });
    };

    const getModuleList = async () => {
      setLoading(true);
      try {
        const res = await ApiService.getAll("common/modules");
        if (res) {
          setModuleList(res);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error updating toggle state:", error);
      }
    };
    const getColumnList = async (col) => {
      setLoading(true);
      try {
        const res = await ApiService.getAll(`common/modules/multilingual/columns?ModuleName=${col}`);
        if (res) {
          const filteredArray = res.filter(item => item.DataField.toLowerCase() !== "id");
          const actionObject = {
            Id: 0,
            ColumnName: "Action",
            DataField: "",
            ColumnIcon: "",
            Type: "action",
            IsSorting: true
          };
          const maxId = filteredArray.reduce((max, item) => Math.max(max, item.Id), 0);
          actionObject.Id = maxId + 1;
          filteredArray.push(actionObject);
          setColumnList(filteredArray);
          setFilterData((prevFilterData) => ({
            ...prevFilterData,
            Index: 0,
            moduleName: col
          }));
        }
      } catch (error) {
        setLoading(false);
        console.error("Error updating toggle state:", error);
      }
    };
    const fetchData = async () => {
      setLoading(true); // Set loading state to true before fetching data
      try {
        const response = await ApiService.post("common/modules/multilingual/list", filterData);
        if (response && response.Data) {
          setDataSource(response.Data);
          setCount(response.TotalCount);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false); // Set loading state to false after fetching data
      }
    };
    const modulehandleChange = (e) => {
      const { name, value } = e.target;
      setModuleValues(value);
      getColumnList(value);
    };

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        // Convert FormData to an array of entries
        const entries = Object.entries(formData);
        // Filter out the entry with the key 'ID' (case-sensitive)
        const filteredEntries = entries.filter(([key, value]) => key.toLowerCase() !== 'id');
        // Map each entry to the desired format
        const columnValues = filteredEntries.map(([key, value]) => ({
            ColumnName: key,
            Value: (value ? value.trim() : '')
        })).filter(({ Value }) => Value !== '');
        const obj ={
          "ModuleName": moduleValues,
          "Id": formData.id,
          "ColumnValues":columnValues
        }
        try {
          const res = await ApiService.put("common/modules/multilingual/update", obj);
          if (res) {
            if (res.statusCode == 200) {
              fetchData();
              enqueueSnackbar('Updated Successfully!',{ variant: 'success', autoHideDuration: 3000 });
            } else {
                setLoading(false);
                enqueueSnackbar(res.statusMessage,{ variant: 'warning', autoHideDuration: 3000 });   
            }
          }
        } catch (error) {
          setLoading(false);
          console.error("Error updating:", error);
        } finally{
          setEditDialogOpen(false);
          setFormData(null); // Hide the form after submission
        }
    };

    const handleCancel = () => {
        setEditDialogOpen(false);
        setFormData(null); // Hide the form
    };
    const handleKeyUp = useRef(_debounce(async (event) => {
        const searchQuery = event.target.value.toLowerCase();
        setSearch(searchQuery);
        setFilterData(prevFilterData => ({ ...prevFilterData, searchKey: searchQuery }));
    },600)).current;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        // console.log("ddd", event, newPage, page);
        setFilterData((prevFilterData) => ({
          ...prevFilterData,
          Index: newPage * rowsPerPage,
        }));
        
      };
    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset page to 0 when rows per page changes
        setFilterData((prevFilterData) => ({
            ...prevFilterData,
            Index: 0,
            Limit: newRowsPerPage, // Update Limit based on the new rows per page
        }));
    };
    return (
        <div>
            {loading && <Loader />}
            <div className="addUser">
            <p className="indicatorText">Translate</p>
                <div style={{marginRight:'20px'}}>
                  <FormControl>
                    <InputLabel>Module</InputLabel>
                    <Select
                      name="Module"
                      value={moduleValues}
                      onChange={modulehandleChange}
                      required
                      className="commonInput"
                    >
                      {moduleList.map((module) => (
                        <MenuItem key={module} value={module}>
                          {module}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="SearchBtn">
                    <img src={searchIcon} alt="" />
                <form >
                    <input type="text" placeholder="Search Translation List" value={search} onChange={e => setSearch(e.target.value)}
                    onKeyUp={handleKeyUp}/>
                </form>
                </div>
            </div>

            <div className='tableContainer'>
                <Paper className="wholeTable"> {/* Use Paper as the container */}
                <Table>
                    <TableHead>
                        <TableRow className="tr">
                        {columnList.map(column => (
                            <TableCell
                            key={column.Id}
                            style={{
                                width: column.Type === 'action' ? '74px': 'auto',
                                position: column.Type === 'action' ? 'sticky' : 'static',
                                right: column.Type === 'action' ? 0 : 'auto',
                                backgroundColor: column.Type === 'action' ? '#fff' : 'inherit', // Ensure sticky column has a background
                                zIndex: column.Type === 'action' ? 1 : 'auto', // Ensure sticky column is above others
                                whiteSpace: 'nowrap', // Prevent text wrapping for better column width control
                              }}
                            className="tableHead"
                            >
                            {column.ColumnName}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSource.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={columnList.length} align="center" style={{ textAlign: "center", verticalAlign: "middle" }}>
                            No data available
                            </TableCell>
                        </TableRow>
                        ) : (
                        dataSource.map((data, index) => (
                            <TableRow key={index}>
                            {columnList.map(column => (
                                <TableCell key={column.Id}
                                    align="left"
                                    style={{
                                        position: column.Type === 'action' ? 'sticky' : 'static',
                                        right: column.Type === 'action' ? 0 : 'auto',
                                        backgroundColor: column.Type === 'action' ? '#fff' : 'inherit',
                                        zIndex: column.Type === 'action' ? 1 : 'auto',
                                        whiteSpace: 'nowrap', // Prevent text wrapping for better column width control
                                    }}>
                                {column.Type === 'action' ? (
                                    <>
                                    <img src={editIcon} alt="" className='action cursor' onClick={() => handleContentEditClick(data)} />
                                    </>
                                ) : (
                                    data[column.DataField] || '-'
                                )}
                                </TableCell>
                            ))}
                            </TableRow>
                        ))
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[15,25,50,75,100]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    style={{
                    position: "sticky",
                    bottom: 0,
                    left:0,
                    backgroundColor: "white",
                    zIndex:2
                    }}
                />
                </Paper>
            </div>

            {/* Render form if a row is selected */}
            {(EditDialogOpen == true) ? (
                <div ref={formRef} className="form-container" style={{ padding: '20px', margin: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
                <p className="indicatorText" style={{justifyContent:'left',marginBottom:'20px'}}>Content</p>
                <form onSubmit={handleSubmit}>
                    <div className="Content">
                        <div className="displayFlex m-b" style={{flexDirection:'row',flexWrap:'wrap'}}>
                        {columnList.filter(col => col.DataField).map(column => (
                            <div key={column.Id} className="example-container" style={{width:'45%'}}>
                                <TextField
                                style={{maxWidth:'100%'}}
                                label={column.ColumnName}
                                name={column.DataField}
                                value={formData[column.DataField] || ''}
                                onChange={handleChange}
                                className="commonInput multiSelectWidth"
                                InputProps={{
                                    endAdornment: (
                                    <Tooltip title={`Supports alphabets, numbers.`}>
                                        <InfoIcon className="Icon-style" />
                                    </Tooltip>
                                    ),
                                }}
                                />
                            </div>
                        ))}
                        </div>
                    </div>
                    <div className="submitButton">
                    <Button type="submit" className="btn">Update</Button>
                    <Button className="cancelBtn" onClick={handleCancel}>Cancel</Button>
                    </div>
                </form>
                </div>
            ):null}
        </div>
    );
};

export default Translate;